export default {
    // dev
    BASE_URL: "https://app.eduzo.wame.sk",
    API_URL: "https://eduzo.wame.sk/api",
    ASSET_URL: "https://eduzo.wame.sk/storage",
    // prod
    // BASE_URL: "https://eduzo.sk",
    // API_URL: "https://admin.eduzo.sk/api",
    // ASSET_URL: "https://admin.eduzo.sk/storage",
    HEADERS: {
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        }
    }
}