<template>
    <b-row class="component-learning-correct-option">
        <b-col cols="12" lg="6" offset-lg="3" class="test-question">
            <BeatLoader v-if="spinners.load_data" />
            <h1><span v-if="question.word_from">{{ question.word_from.word }}</span></h1>
        </b-col>
        <b-col cols="12" lg="6" offset-lg="3" class="question-options">
            <b-button
                v-for="(button, button_id) in question.words"
                :key="button_id"
                :id="button_id"
                @click="checkCorrectAnswer(button_id)"
                class="question-options__button"
                variant="primary-white"
            >
                {{ button }}
            </b-button>

            <b-button
                v-show="submit_answer"
                @click="sendNextQuestion()"
                class="question-options__next-question"
                variant="primary-lighter"
            >
                <BeatLoader v-if="spinners.post_data" />
                <span v-else>Ďaľšie</span>
            </b-button>
        </b-col>
    </b-row>
</template>

<script>
import config from "@/config";
import BeatLoader from 'vue-spinner/src/BeatLoader.vue';

export default {
    name: "LearningCorrectOption",
    components: {
        BeatLoader,
    },
    data() {
        return {
            question: {},
            submit_answer: false,
            correct_answer: '',

            spinners: {
                load_data: false,
                post_data: false,
            },
        }
    },
    mounted() {
        this.getStudyQuestionFirst()
    },
    methods: {
        getStudyQuestionFirst() {
            this.spinners.load_data = true
            this.axios.get(config.API_URL + '/one-answer-test/getWord'
                + '?slug=' + this.$route.params.slug, config.HEADERS)
                .then((response) => {
                    let responseData = response.data
                    if (responseData.code === 200) {
                        this.question = responseData.data
                    } else if (responseData.code === 401) {
                        this.$store.dispatch('logoutUser', false)
                    }
                    this.spinners.load_data = false
                }, (error) => {
                    console.warn(error);
                    this.spinners.load_data = false
                });
        },
        checkCorrectAnswer(button_id) {
            if (this.submit_answer) return false
            this.$emit('possibility-change', true)
            this.submit_answer = true

            let selectAnswer = document.getElementById(button_id);
            let correctAnswer = document.getElementById(this.question.word_to.id);

            if (Number(button_id) === this.question.word_to.id) {
                selectAnswer.classList.add('correct-answer')
                this.correct_answer = 'hard'
            } else {
                selectAnswer.classList.add('incorrect-answer')
                correctAnswer.classList.add('correct-answer')
                this.correct_answer = 'dont_know'
            }

            this.submit_answer = true
        },
        sendNextQuestion() {
            if (this.spinners.post_data) return false
            this.$emit('possibility-change', false)

            const wordAnswer = new FormData()
            wordAnswer.set('word_id', this.question.word_from.id);
            wordAnswer.set('react', this.correct_answer);
            wordAnswer.set('slug', this.$route.params.slug);

            this.postNextQuestion(wordAnswer)
        },
        postNextQuestion(answer_data) {
            this.spinners.post_data = true
            this.axios.post(config.API_URL + '/one-answer-test/reactOnWord', answer_data,config.HEADERS)
                .then((response) => {
                    let responseData = response.data
                    if (responseData.code === 200) {
                        this.question = responseData.data
                        this.submit_answer = false
                        this.correct_answer = ''
                    } else if (responseData.code === 401) {
                        this.$store.dispatch('logoutUser', false)
                    }
                    this.spinners.post_data = false
                }, (error) => {
                    console.warn(error);
                    this.spinners.post_data = false
                });
        }
    }
}
</script>

<style lang="scss" scoped>
.component-learning-correct-option::v-deep {

    .test-question {
        @extend %flex-column-center;

        height: 100%;
        min-height: em(300);
    }

    .question-options {
        margin-top: em(50);
        margin-bottom: em(50);
        @extend %flex-column-center;

        &__button {
            margin-bottom: em(10);
            width: 100%;
        }

        .correct-answer {
            background-color: $green;
            transition: transform 0.2s ease;
            transform: scale(1.1);
        }

        .incorrect-answer {
            background-color: $red;
        }

        &__next-question {
            min-width: em(200, 20);
            margin-top: em(50);
        }
    }

    @media (max-width: 991px) {
        .test-question {
            min-height: 20vh;
        }

        .question-options {
            margin-top: 0;
        }
    }
}
</style>