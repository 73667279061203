<template>
    <img :src="body_asset" class="component-body-image-background img-drag-none m-display-none" alt="image" />
</template>

<script>
import body_asset from "@/assets/body_asset.svg";

export default {
    name: "BodyImageBackground",
    data() {
        return {
            body_asset: body_asset,
        }
    },
}
</script>

<style lang="scss" scoped>
.component-body-image-background::v-deep {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 0;
}
</style>