<template>
    <b-navbar class="component-main-menu">
        <div class="main-menu-container container">
            <b-navbar-brand href="#">{{ title }}</b-navbar-brand>

            <div class="nav-icons">
                <img
                    @click="$router.push({ name: 'PageHome' }, () => {})"
                    :src="home"
                    class="nav-icons__svg"
                    alt="image"
                />

                <img
                    @click="$router.push({ name: 'PageLibrary' }, () => {})"
                    :src="search"
                    class="nav-icons__svg"
                    alt="image"
                />

                <img
                    v-if="$store.getters.check_permission('manage own groups')"
                    @click="$router.push({ name: 'PageStudyGroupCreate' }, () => {})"
                    :src="add"
                    class="nav-icons__svg"
                    alt="image"
                />
<!--                <b-nav-item-dropdown>-->
<!--                    <template #button-content>-->
<!--                        <img :src="add" class="nav-icons__svg" alt="image" />-->
<!--                    </template>-->
<!--                    <b-dropdown-item @click="$router.push({ name: 'PageStudyGroupCreate' }, () => {})">Nová skupina</b-dropdown-item>-->
<!--&lt;!&ndash;                    <b-dropdown-item @click="$router.push({ name: 'PageTestCreate' }, () => {})">Nový test</b-dropdown-item>&ndash;&gt;-->
<!--                </b-nav-item-dropdown>-->
                <img
                    @click="$router.push({ name: 'PageProfile' }, () => {})"
                    :src="user"
                    class="nav-icons__svg"
                    alt="image"
                />
            </div>
        </div>
    </b-navbar>
</template>

<script>
import home from '@/assets/main_menu/home_icon.svg';
import search from '@/assets/main_menu/search.svg';
import add from '@/assets/main_menu/add_icon.svg';
import user from '@/assets/main_menu/user_icon.svg';

export default {
    name: "MainMenu",
    props: {
        title: {
            type: String,
            default() {
                return 'EDUZO'
            }
        }
    },
    data() {
        return {
            home: home,
            search: search,
            add: add,
            user: user,
        }
    },
}
</script>

<style lang="scss" scoped>
.component-main-menu::v-deep {
    margin-bottom: em(50);
    background-color: $blue;
    z-index: 2;

    .main-menu-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .navbar-brand {
            font-size: em(25);
            color: $white;
        }

        .nav-icons {
            display: flex;

            &__svg {
                margin: 0 em(20);
                cursor: pointer;
            }

            //.b-nav-dropdown {
            //    list-style: none;
            //
            //    * {
            //        outline: 0;
            //        border: 0;
            //    }
            //
            //    .nav-link {
            //        padding: 0;
            //    }
            //
            //    .dropdown-item:hover, .dropdown-item:focus {
            //        background-color: $primary-lighter;
            //        border-radius: em(4);
            //    }
            //
            //    .dropdown-toggle::after {
            //        display: none;
            //    }
            //
            //    .dropdown-menu {
            //        background-color: $primary;
            //        padding: em(5) em(20);
            //        left: 50%;
            //        transform: translate(-50%, 0);
            //        top: em(50);
            //
            //        &::after {
            //            content: '';
            //            position: absolute;
            //            left: 50%;
            //            transform: translate(-50%, 0);
            //            top: em(-15);
            //            width: 0;
            //            height: 0;
            //            border-left: 20px solid transparent;
            //            border-right: 20px solid transparent;
            //            border-bottom: 20px solid $primary;
            //            clear: both;
            //        }
            //    }
            //}
        }
    }

    @media (max-width: 767px) {
        margin-bottom: 0;

        .main-menu-container {
            display: flex;
            justify-content: normal;
            align-items: normal;

            .navbar-brand {
                font-size: em(20);
            }

            .nav-icons {
                display: flex;
                justify-content: space-around;
                align-items: center;
                position: fixed;
                bottom: 0;
                left: 0;

                width: 100%;
                padding: em(5);
                //padding-bottom: env(safe-area-inset-bottom, 10px);
                @supports (padding-bottom: env(safe-area-inset-bottom)) {
                    padding-bottom: env(safe-area-inset-bottom, 5px);
                }

                border-radius: em(15) em(15) 0 0;
                background-color: $gray-darken;

                &__svg {
                    margin: 0;
                }

                //.b-nav-dropdown {
                //    .dropdown-menu {
                //        top: em(-75);
                //        right: em(-20);
                //
                //        &::after{
                //            top: 100%;
                //            border-top: 20px solid $primary;
                //            border-bottom: 0;
                //        }
                //    }
                //}
            }
        }
    }
}
</style>