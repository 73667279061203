<template>
    <div class="component-not-run-tests">
        <hr class="main-hr">
        <div class="not-run-tests-head">
            <h2>NEODOVZDANÉ TESTY</h2>
            <p class="not-run-tests-description">
                <small>(spustené testy, ktoré ste ešte neodovzdali)</small>
            </p>
            <a @click="redirectProfileTest()" class="profile-all-tests">Zobraziť všetky testy</a>
        </div>

        <StudentTestResultsList
            :test_list="test_list"
            :records="pagination.records"
            :load_data="spinners.load_data"
            :results_explanation="false"
        />
        <VuePagination
            id="paginator-not-run-tests"
            :per_page="pagination.per_page"
            :actual_page.sync="pagination.actual_page"
            :records="pagination.records"
            :load_data="spinners.load_data"
            :query_name="pagination.query_name"
        />
    </div>
</template>

<script>
import config from "@/config";
import StudentTestResultsList from "@/components/profile/StudentTestResultsList";
import VuePagination from "@/components/VuePagination";

export default {
    name: "NotRunTests",
    components: {
        StudentTestResultsList,
        VuePagination,
    },
    data() {
        return {
            pagination: {
                per_page: 5,
                actual_page: 1,
                records: 0,
                query_name: 'testy',
            },
            test_list: [],
            spinners: {
                load_data: false,
            },
        }
    },
    watch: {
        '$route.query.testy': function () {
            this.setActualPage()
        },
    },
    created() {
        this.setActualPage()
    },
    methods: {
        setActualPage() {
            this.$route.query[this.pagination.query_name]
                ? this.pagination.actual_page = Number(this.$route.query[this.pagination.query_name])
                : this.pagination.actual_page = 1
            this.getStudentTestsResults()
        },
        getStudentTestsResults() {
            this.spinners.load_data = true
            this.axios.get(config.API_URL + '/test/getTestList'
                + '?user_slug=' + this.$store.getters.user.slug
                + '&perPage=' + this.pagination.per_page
                + '&page=' + this.pagination.actual_page
                + '&test_state_filter=' + '0'
                + '&orderBy=' + 'created_at'
                + '&ordering=' + 'DESC', config.HEADERS)
                .then((response) => {
                    let responseData = response.data
                    if (responseData.code === 200) {
                        this.test_list = responseData.data.data
                        this.pagination.records = responseData.data.total
                    } else if (responseData.code === 401) {
                        this.$store.dispatch('logoutUser', false)
                    }
                    this.spinners.load_data = false
                }, (error) => {
                    console.warn(error);
                    this.spinners.load_data = false
                });
        },
        redirectProfileTest() {
            this.$router.push({ name: 'PageProfileTestResults' }, () => {})
        }
    }
}
</script>

<style lang="scss" scoped>
.component-not-run-tests::v-deep {

    .main-hr {
        margin: em(50) 0;
    }

    .not-run-tests-head {
        position: relative;
        margin-bottom: em(20);

        .profile-all-tests {
            position: absolute;
            bottom: 0;
            right: em(20);
            cursor: pointer;
        }
    }

    @media (max-width: 991px) {
        .main-hr {
            margin: em(10) 0;
        }

        .not-run-tests-head {
            .profile-all-tests {
                position: relative;
                right: 0;
            }
        }
    }
}
</style>