<template>
    <div class="single-student-test-results-list-card" :class="test.state === 1 ? 'user-test-success' : 'user-test-failed'">
        <b-row class="test-result-card">
            <b-col cols="12" lg="3" v-if="test.study_group">{{ test.study_group.name }}</b-col>
            <b-col cols="6" lg="2">{{ test.test_number }}</b-col>
            <b-col cols="3" lg="1" class="m-display-none">{{ test.answering_time_number }} min</b-col>
            <b-col cols="3" lg="1">
                <span v-if="test.state === 1">{{ test.right_answers_count }}/{{ test.question_count }}</span>
                <span v-else>{{ test.question_count }}</span>
            </b-col>
            <b-col cols="3" lg="2" class="m-display-none">
                <div v-if="test.state === 1">{{ moment(test.end_time).locale('sk').format('lll') }}</div>
                <div v-else>-</div>
            </b-col>
            <b-col cols="3" lg="1">
                <div v-if="test.teacher_control === 1">áno</div>
                <div v-else>nie</div>
            </b-col>
            <b-col cols="12" lg="2" class="run-test">
                <b-button v-if="test.state === 0" @click="startTest(test.test_number, test.id)" variant="primary">Spustiť test</b-button>
                <b-button v-else @click="checkTest(test.study_group.slug, test.test_number, test.id)" variant="primary-purple">Skontrolovať</b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: "StudentTestResultsListCard",
    props: {
        test: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    methods: {
        startTest(test_number, test_id) {
            this.$router.push({ name: 'PageTestStart', params: { test_number: test_number, test_id: test_id }})
        },
        checkTest(group_slug, test_number, test_id) {
            this.$router.push({ name: 'PageTestControl', params: { slug: group_slug, test_number: test_number }, query: { test: test_id } })
        },
    }
}
</script>

<style lang="scss" scoped>
.single-student-test-results-list-card::v-deep {
    border-radius: em(5);
    margin-bottom: em(10);
    padding: em(10) 0;

    .test-result-card {
        > div {
            @extend %flex-column-center;
        }
    }

    .run-test {
        cursor: pointer;

        .btn {
            margin: 0;
            padding: 0 em(10);
            font-size: 1rem;
        }
    }
}

.user-test-success {
    background-color: $green;
}

.user-test-failed {
    background-color: $red;
}
</style>