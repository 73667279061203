<template>
    <div class="component-response-error">
        <div v-for="(error, index) in errors" :key="index" class="error-message">
            <p class="response-type response-type__failed">{{ error }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "ResponseError",
    props: {
        errors: {
            type: Object,
            default() {
                return {}
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.component-response-error::v-deep {
    width: 100%;
    max-width: em(400);
    margin: em(20) auto;

    .error-message {
        margin: em(5);
    }
}
</style>