import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'

import PageLogin from '@/views/login/PageLogin'
import PageRegistration from '@/views/login/PageRegistration'

import PageHome from '@/views/PageHome'

import PageProfile from '@/views/PageProfile'
import PageProfileTestResults from '@/views/profile/PageProfileTestResults'
import PageProfileSettings from '@/views/profile/PageProfileSettings'

import PageStudyGroupCreate from '@/views/study_group/PageStudyGroupCreate'
import PageStudyGroupShare from '@/views/study_group/PageStudyGroupShare'

import PageStudyGroup from '@/views/PageStudyGroup'
import PageStudyGroupStudents from '@/views/study_group/PageStudyGroupStudents'
import PageStudyGroupPackages from '@/views/study_group/PageStudyGroupPackages'
import PageStudyGroupTests from '@/views/study_group/PageStudyGroupTests'
import PageStudyGroupSettings from '@/views/study_group/PageStudyGroupSettings'

import PageTestCreate from '@/views/study_group/teacher_test/PageTestCreate'
import PageTestResults from '@/views/study_group/teacher_test/PageTestResults'
import PageTestControl from '@/views/study_group/teacher_test/PageTestControl'

import PageStudentTest from '@/views/study_group/student_test/PageStudentTest'
import PageTestStart from '@/views/study_group/student_test/PageTestStart'
import PageTestRun from '@/views/study_group/student_test/PageTestRun'
import PageTestFinish from '@/views/study_group/student_test/PageTestFinish'

import PageLearning from '@/views/PageLearning'
import PageLibrary from '@/views/PageLibrary'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'PageLogin',
        component: PageLogin,
        meta: {
            loggedUser: true, // check if the user is logged in
        }
    },
    {
        path: '/registracia',
        name: 'PageRegistration',
        component: PageRegistration,
        meta: {
            loggedUser: true,
        }
    },
    {
        path: '/domov',
        name: 'PageHome',
        component: PageHome,
        meta: {
            requireAuth: true, // AUTH required
        }
    },
    {
        path: '/profil',
        name: 'PageProfile',
        component: PageProfile,
        meta: {
            requireAuth: true,
        }
    },
    {
        path: '/profil/vysledky-testov',
        name: 'PageProfileTestResults',
        component: PageProfileTestResults,
        meta: {
            requireAuth: true,
        }
    },
    {
        path: '/profil/nastavenia',
        name: 'PageProfileSettings',
        component: PageProfileSettings,
        meta: {
            requireAuth: true,
        }
    },
    {
        path: '/vytvorenie-studijnej-skupiny',
        name: 'PageStudyGroupCreate',
        component: PageStudyGroupCreate,
        meta: {
            requireAuth: true,
        }
    },
    {
        path: '/zdielanie-studijnej-skupiny/:slug',
        name: 'PageStudyGroupShare',
        component: PageStudyGroupShare,
        meta: {
            requireAuth: true,
        }
    },
    {
        path: '/studijna-skupina/:slug',
        name: 'PageStudyGroup',
        component: PageStudyGroup,
        children: [
            {
                path: 'studenti',
                name: 'PageStudyGroupStudents',
                component: PageStudyGroupStudents,
                meta: {
                    requireAuth: true,
                }
            },
            {
                path: 'balicky',
                name: 'PageStudyGroupPackages',
                component: PageStudyGroupPackages,
                meta: {
                    requireAuth: true,
                }
            },
            {
                path: 'testy',
                name: 'PageStudyGroupTests',
                component: PageStudyGroupTests,
                meta: {
                    requireAuth: true,
                }
            },
            {
                path: 'nastavenia',
                name: 'PageStudyGroupSettings',
                component: PageStudyGroupSettings,
                meta: {
                    requireAuth: true,
                }
            },
            {
                path: 'vytvorenie-testu',
                name: 'PageTestCreate',
                component: PageTestCreate,
                meta: {
                    requireAuth: true,
                }
            },
            {
                path: 'vysledky-testu/:test_number',
                name: 'PageTestResults',
                component: PageTestResults,
                meta: {
                    requireAuth: true,
                }
            },
            {
                path: 'kontrola-testu/:test_number',
                name: 'PageTestControl',
                component: PageTestControl,
                meta: {
                    requireAuth: true,
                }
            },
        ],
        meta: {
            requireAuth: true,
        }
    },
    {
        path: '/test/:test_number',
        name: 'PageStudentTest',
        component: PageStudentTest,
        children: [
            {
                path: 'informacie/:test_id',
                name: 'PageTestStart',
                component: PageTestStart,
                meta: {
                    requireAuth: true,
                }
            },
            {
                path: 'vyplnanie/:test_id',
                name: 'PageTestRun',
                component: PageTestRun,
                meta: {
                    requireAuth: true,
                }
            },
            {
                path: 'ukonceny',
                name: 'PageTestFinish',
                component: PageTestFinish,
                meta: {
                    requireAuth: true,
                }
            },
        ],
        meta: {
            requireAuth: true,
        }
    },
    {
        path: '/vzdelavanie/:slug',
        name: 'PageLearning',
        component: PageLearning,
        meta: {
            requireAuth: true,
        }
    },
    {
        path: '/kniznica',
        name: 'PageLibrary',
        component: PageLibrary,
        meta: {
            requireAuth: true,
        }
    },
    {
        path: '/zabudnute-heslo',
        name: 'PageForgotPassword',
        component: () => import(/* webpackChunkName: "page_forgot_password" */ '@/views/login/PageForgotPassword'),
        meta: {
            loggedUser: true,
        }
    },
    {
        path: '/zmena-hesla',
        name: 'PageResetPassword',
        component: () => import(/* webpackChunkName: "page_reset_password" */ '@/views/login/PageResetPassword'),
        meta: {
            loggedUser: true,
        }
    },
    {
        path: '/404',
        name: 'Page404',
        component: () => import(/* webpackChunkName: "page_404" */ '@/views/Page404')
    },
    {
        path: '*',
        name: '404',
        beforeEnter: (to, from, next) => {
            next({ name: 'Page404' })
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(page => page.meta.requireAuth)) {
        if (store.getters.user && store.getters.user.access_token) {
            next()
        } else {
            store.commit('clearVuexData')
            next({ name: 'PageLogin' })
        }
    } else if (to.matched.some(page => page.meta.loggedUser)) {
        store.getters.user.access_token ? next({ name: 'PageHome' }) : next()
    } else {
        next()
    }
})

export default router
