<template>
    <div class="component-study-group-students">

        <b-row class="students-group-head">
            <b-col cols="12" lg="8" offset-lg="2" class="title-item">Meno</b-col>
        </b-row>
        <b-row v-for="student in students" :key="student.id" class="student-group-body">
            <b-col cols="10" lg="7" offset-lg="2" class="student-name">
                {{ student.name }} {{ student.surname }}
            </b-col>
            <b-col cols="2">
                <b-icon
                    v-if="$store.getters.check_permission('manage own groups')"
                    @click="teacherRemoveStudent(student)"
                    class="remove-user-icon"
                    icon="x-circle"
                ></b-icon>
                <b-icon
                    v-if="student.slug === $store.state.user.slug"
                    @click="studentDelete(student)"
                    class="remove-user-icon"
                    icon="x-circle"
                ></b-icon>
            </b-col>
        </b-row>

<!--    toto bolo predtym, list studentov s ikonkami pre preklik do statistik a preklik na testy    -->
<!--        <b-row class="students-group-head">-->
<!--            <b-col cols="6" lg="4" offset-lg="2" class="title-item">Meno</b-col>-->
<!--            <b-col cols="3" lg="2">Šatistika</b-col>-->
<!--            <b-col cols="3" lg="2">Testy</b-col>-->
<!--        </b-row>-->
<!--        <b-row v-for="student in students" :key="student.id" class="student-group-body">-->
<!--            <b-col cols="6" lg="4" offset-lg="2" class="student-name">-->
<!--                {{ student.name }}-->
<!--            </b-col>-->
<!--            <b-col cols="3" lg="2" class="flexbox-center-body">-->
<!--                <img :src="student_statistic" class="student-statistic" alt="image" />-->
<!--            </b-col>-->
<!--            <b-col cols="3" lg="2" class="flexbox-center-body">-->
<!--                <img :src="student_test" class="student-test" alt="image" />-->
<!--            </b-col>-->
<!--        </b-row>-->

        <ResponseError v-if="response_type.failed" :errors="response_type.failed" />
    </div>
</template>

<script>
import config from "@/config";
import student_statistic from "@/assets/study_group/student_statistic.svg";
import student_test from "@/assets/study_group/student_test.svg";
import ResponseError from "@/components/ResponseError";

export default {
    name: "StudyGroupStudents",
    components: {
        ResponseError
    },
    props: {
        students: {
            type: Array,
            default() {
                return []
            }
        },
    },
    data() {
        return {
            student_statistic: student_statistic,
            student_test: student_test,
            response_type: {
                success: null,
                failed: null,
            }
        }
    },
    methods: {
        teacherRemoveStudent(student_data) {
            const postData = new FormData();
            postData.set('users[0]', student_data.id);
            postData.set('group_slug', this.$route.params.slug);
            this.axios.post(config.API_URL + '/group/detachUsers', postData, config.HEADERS)
                .then((response) => {
                    if (response.data.code === 200) {
                        this.$emit('update-students-list')
                        this.response_type.failed = null
                    } else {
                        this.response_type.failed = response.data.errors
                    }
                }, (error) => {
                    console.warn(error)
                });
        },
        studentDelete() {
            const postData = new FormData();
            postData.set('group_slug', this.$route.params.slug);
            this.axios.post(config.API_URL + '/group/abaddonGroup', postData, config.HEADERS)
                .then((response) => {
                    console.log('asdf', response)
                    if (response.data.code === 200) {
                        this.$router.replace({ name: 'PageHome' }, () => {})
                        this.response_type.failed = null
                    } else {
                        this.response_type.failed = response.data.errors
                    }
                }, (error) => {
                    console.warn(error)
                });
        }
    }
}
</script>

<style lang="scss" scoped>
.component-study-group-students::v-deep {
    margin: 0 auto em(50);

    .students-group-head {
        width: 100%;
        max-width: em(900);
        margin: 0 auto;

        .title-item {
            text-align: left;
        }
    }

    .student-group-body {
        background-color: $purple-light;
        border-radius: em(10);
        margin: 0 auto em(10);
        padding: em(10) 0;
        width: 100%;
        max-width: em(900);

        .student-name {
            text-align: left;
        }

        .student-statistic, .student-test {
            cursor: pointer;
        }

        .remove-user-icon {
            cursor: pointer;
        }
    }

    .flexbox-center-body {
        @extend %flex-column-center;
        justify-content: center;
    }
}
</style>