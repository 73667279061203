<template>
    <div id="app">
        <router-view/>
        <WameCopyright />
    </div>
</template>

<script>
import config from "@/config";
import WameCopyright from "@/components/WameCopyright";

export default {
    components: {
        WameCopyright
    },
    metaInfo() {
        return {
            titleTemplate: '%s EDUZO',
            htmlAttrs: {
                lang: 'sk',
                amp: true
            },
            link: [
                { rel: 'canonical', href: this.current_page }
            ],
            meta: [
                { charset: 'utf-8' },
                { vmid: 'description', name: 'description', content: this.meta_data.description },
                { vmid: 'robots', name: 'robots', content: 'index, follow' },
                { vmid: 'og:url', name:'og:url', content: config.BASE_URL + this.current_page },
                { vmid: 'og:title', name:'og:title', content: 'EDUZO' },
                { vmid: 'og:description', name: 'og:description', content: this.meta_data.description },
                // { vmid: 'og:image', name:'og:image', content: this.$store.getters.settings.meta_logo },
                { vmid: 'og:type', name:'og:type', content: 'website' },
            ]
        }
    },
    beforeCreate() {
        if (this.$store.getters.user) {
            config.HEADERS.headers['Authorization'] = `Bearer ${ this.$store.getters.user.access_token }`;
        }
    },
    data() {
        return {
            current_page: '',
            meta_data: {
                description: 'Webový portál na učenie cudzích jazykov formou balíčkov.',
            }
        }
    },
    watch: {
        '$route': {
            handler(value) {
                this.current_page = value.fullPath
            }, immediate: true
        }
    },
}
</script>

<style lang="scss">
#app {
    position: relative;
    min-height: 100vh;
}
</style>
