<template>
    <div class="component-project-list">
        <hr class="main-hr">
        <div class="project-list-head">
            <h2>ŠTÚDIUM BALÍKOV</h2>
            <p class="project-list-head__description">
                <small>(balíky, ktoré študujete)</small>
            </p>
            <a @click="redirectPackageSearch()" class="project-list-head__package-search">Vyhľadať ďaľšie balíky</a>
        </div>

        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div v-if="spinners.load_data" class="swiper-slide swiper-slide-load">
                    <BeatLoader />
                </div>
                <div v-for="project_card in packages_data" :key="project_card.id" class="swiper-slide">
                    <ProjectListCard :project_card="project_card" />
                </div>
                <div class="m-auto" v-if="!spinners.load_data && packages_data.length === 0">
                    Zatiaľ nemáte priradený žiadny balík na študovanie.
                </div>
            </div>
            <div class="swiper-pagination"></div>
        </div>
    </div>
</template>

<script>
import config from "@/config";
import project_play_white from '@/assets/project_play_white.svg'

import ProjectListCard from "@/components/home/ProjectListCard";
import BeatLoader from 'vue-spinner/src/BeatLoader.vue';

import Swiper from "swiper/bundle"
import "swiper/swiper-bundle.css"

export default {
    name: "ProjectList",
    components: {
        ProjectListCard,
        BeatLoader,
    },
    data() {
        return {
            project_play_white: project_play_white,

            config_swiper: {
                init: true,
                observer: true,
                observeParents: true,
                observeSlideChildren: true,
                slidesPerView: 1.15,
                centeredSlides: true,
                spaceBetween: 10,
                breakpoints: {
                    992: {
                        slidesPerView: 4,
                        slidesPerGroup: 4,
                        centeredSlides: false,
                    }
                },
            },
            packages_data_swiper: '',

            pagination: {
                per_page: 32,
                actual_page: 1,
                records: 0,
                lazy_load: true,
            },
            packages_data: [],

            spinners: {
                load_data: false,
            },
        }
    },
    mounted() {
        this.$set(this, 'packages_data_swiper', new Swiper('.component-project-list .swiper-container', this.config_swiper));
        this.getStudyGroupsPackages()

        let self = this;
        this.packages_data_swiper.on('slideChange', function() {
            let differenceIndex = (self.packages_data.length - self.packages_data_swiper.activeIndex)
            if (differenceIndex < 11) {
                if (self.packages_data.length !== self.pagination.records) {
                    if (self.pagination.lazy_load) {
                        self.getStudyGroupsPackages();
                        self.pagination.lazy_load = false;
                    }
                }
            }
        });
    },
    methods: {
        getStudyGroupsPackages() {
            this.spinners.load_data = true
            this.axios.get(config.API_URL + '/packages/user/getPackages'
                + '?perPage=' + this.pagination.per_page
                + '&page=' + this.pagination.actual_page, config.HEADERS)
                .then((response) => {
                    let responseData = response.data
                    if (responseData.code === 200) {
                        responseData.data.data.forEach(package_card => {
                            // let a = this.all_blog.find(x => x.id === blog.id)
                            this.packages_data.push(package_card);
                        })
                        this.pagination.records = responseData.data.total

                        let self = this;
                        this.$nextTick(() => {
                            self.packages_data_swiper.update(true);
                            self.pagination.actual_page += 1;
                            self.pagination.lazy_load = true;
                            // self.spinner.blog = false
                        });
                    } else if (responseData.code === 401) {
                        this.$store.dispatch('logoutUser', false)
                    }
                    this.spinners.load_data = false
                }, () => {
                    this.spinners.load_data = false
                });
        },
        redirectPackageSearch() {
            this.$router.push({ name: 'PageLibrary' }, () => {})
        },
    }
}
</script>

<style lang="scss" scoped>
.component-project-list::v-deep {
    margin-bottom: em(30);

    .main-hr {
        margin: em(50) 0;
    }

    .project-list-head {
        position: relative;
        margin-bottom: em(20);

        &__package-search {
            position: absolute;
            bottom: 0;
            right: em(20);
            cursor: pointer;
        }
    }

    .swiper {
        &-container {
            background: $white-dark;
            padding: 20px;
            border-radius: 5px;
        }

        &-slide-load {
            margin: 0 auto !important;
        }
    }

    @media (max-width: 991px) {
        .main-hr {
            margin: em(10) 0;
        }

        .project-list-head {
            &__package-search {
                position: relative;
                right: 0;
            }
        }
    }
}
</style>