<template>
    <div class="component-study-group-menu">
        <div v-for="item in menu_items" :key="item.id" class="study-group-menu" v-show="checkPermissionGroup(item.permission)">
            <div v-if="checkPermissionGroup(item.permission)">
                <img
                    v-if="checkPermissionGroup(item.permission)"
                    class="study-group-menu__item"
                    :class="item.name"
                    :src="item.image"
                    @click="setSubpage(item.page)"
                    alt="image"
                />

                <img
                    class="study-group-menu__active"
                    v-show="item.nested_pages.includes($route.name)"
                    :src="study_group_menu_active"
                    alt="image"
                />
            </div>
        </div>
    </div>
</template>

<script>
import menu_students from "@/assets/study_group/menu_students.svg";
import menu_packages from "@/assets/study_group/menu_packages.svg";
import menu_test from "@/assets/study_group/menu_test.svg";
import menu_settings from "@/assets/study_group/menu_settings.svg";
import study_group_menu_active from "@/assets/study_group/menu_active.svg";

export default {
    name: "StudyGroupMenu",
    data() {
        return {
            study_group_menu_active: study_group_menu_active,
            menu_items: [
                {
                    id: 0,
                    name: 'students',
                    image: menu_students,
                    page: 'PageStudyGroupStudents',
                    nested_pages: [ 'PageStudyGroupStudents' ],
                    permission: ''
                },
                {
                    id: 1,
                    name: 'packages',
                    image: menu_packages,
                    page: 'PageStudyGroupPackages',
                    nested_pages: [ 'PageStudyGroupPackages' ],
                    permission: ''
                },
                {
                    id: 2,
                    name: 'test',
                    image: menu_test,
                    page: 'PageStudyGroupTests',
                    nested_pages: [ 'PageStudyGroupTests', 'PageTestCreate', 'PageTestResults', 'PageTestControl' ],
                    permission: ''
                },
                {
                    id: 3,
                    name: 'settings',
                    image: menu_settings,
                    page: 'PageStudyGroupSettings',
                    nested_pages: [ 'PageStudyGroupSettings' ],
                    permission: 'manage own groups'
                },
            ],
            actual_subpage: 'students',
        }
    },
    methods: {
        setSubpage(page) {
            this.$router.push({ name: page, params: { slug: this.$route.params.slug } }, () => {})
        },
        checkPermissionGroup(permission) {
            if (permission === 'manage own groups') {
                return !!this.$store.getters.check_permission('manage own groups');
            } else {
                return true
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.component-study-group-menu::v-deep {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    max-width: 500px;
    margin: 0 auto em(50);

    .study-group-menu {
        position: relative;

        &__item {
            cursor: pointer;
        }

        &__active {
            position: absolute;
            left: -10px;
            bottom: -10px;
        }
    }

    @media (max-width: 991px) {
        justify-content: space-between;
    }
}
</style>