<template>
    <div class="page-study-group-tests">
        <StudyGroupTestsListTeacher
            v-if="$store.getters.check_permission('manage own groups') || $store.getters.user.user_type_id === 1"
            :test_list="test_list"
        />
        <StudyGroupTestsListStudent
            v-if="$store.getters.user.user_type_id === 2"
            :test_list="test_list"
        />
        <VuePagination
            id="paginator-student-group-tests"
            v-show="spinners.load_data || pagination.records !== 0"
            :per_page="pagination.per_page"
            :actual_page.sync="pagination.actual_page"
            :records="pagination.records"
            :load_data="spinners.load_data"
            :query_name="pagination.query_name"
        />

        <p v-if="!spinners.load_data && test_list.length === 0" class="mt-5">
            Pre túto skupinu neboli zatiaľ vytvorené žiadne testy.
        </p>

        <b-button
            v-if="$store.getters.check_permission('manage own groups')"
            @click="goCreateNewTest()"
            variant="primary-lighter"
        >
            PRIDAŤ NOVÝ TEST
        </b-button>
    </div>
</template>

<script>
import config from "@/config";
import StudyGroupTestsListTeacher from "@/components/study_group/StudyGroupTestsListTeacher";
import StudyGroupTestsListStudent from "@/components/study_group/StudyGroupTestsListStudent";
import VuePagination from "@/components/VuePagination";

export default {
    name: "PageStudyGroupTests",
    components: {
        VuePagination,
        StudyGroupTestsListTeacher,
        StudyGroupTestsListStudent,
    },
    data() {
        return {
            pagination: {
                per_page: 10,
                actual_page: 1,
                records: 0,
                query_name: 'strana',
            },
            test_list: [],
            spinners: {
                load_data: false,
            },
        }
    },
    watch: {
        '$route.query.strana': function () {
            this.setActualPage()
        },
    },
    mounted() {
        this.setActualPage()
    },
    methods: {
        setActualPage() {
            this.$route.query[this.pagination.query_name]
                ? this.pagination.actual_page = Number(this.$route.query[this.pagination.query_name])
                : this.pagination.actual_page = 1
            this.getStudyGroupTests()
        },
        getStudyGroupTests() {
            if (this.$store.getters.user.user_type_id === 1) {
                this.getTestTeacher()
            } else if (this.$store.getters.user.user_type_id === 2) {
                this.getTestStudents()
            }
        },
        getTestTeacher() {
            this.spinners.load_data = true
            this.axios.get(config.API_URL + '/test/getTestNumbersList'
                + '?perPage=' + this.pagination.per_page
                + '&page=' + this.pagination.actual_page
                + '&group_slug=' + this.$route.params.slug, config.HEADERS)
                .then((response) => {
                    let responseData = response.data
                    if (responseData.code === 200) {
                        this.test_list = responseData.data.data
                        this.pagination.records = responseData.data.total
                    } else if (responseData.code === 401) {
                        this.$store.dispatch('logoutUser', false)
                    }
                    this.spinners.load_data = false
                }, (error) => {
                    console.warn(error);
                    this.spinners.load_data = false
                });
        },
        getTestStudents() {
            this.spinners.load_data = true
            this.axios.get(config.API_URL + '/test/getTestList'
                + '?perPage=' + this.pagination.per_page
                + '&page=' + this.pagination.actual_page
                + '&group_slug=' + this.$route.params.slug
                + '&user_slug=' + this.$store.getters.user.slug, config.HEADERS)
                .then((response) => {
                    let responseData = response.data
                    if (responseData.code === 200) {
                        this.test_list = responseData.data.data
                        this.pagination.records = responseData.data.total
                    } else if (responseData.code === 401) {
                        this.$store.dispatch('logoutUser', false)
                    }
                    this.spinners.load_data = false
                }, (error) => {
                    console.warn(error);
                    this.spinners.load_data = false
                });
        },
        goCreateNewTest() {
            this.$router.push({ name: 'PageTestCreate' }, () => {})
        },
    }
}
</script>

<style lang="scss" scoped>
.page-study-group-tests::v-deep {
    //
}
</style>