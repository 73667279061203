<template>
    <div class="page-test-run">
        <div class="test-head">
            <div class="test-count">
                <p>Slová</p>
                <p class="test-count__number">{{ testQuestionCount }}</p>
            </div>
            <div class="test-time">
                <p>Čas</p>
                <div class="test-time__timer">{{ formatDifference }}</div>
            </div>
        </div>

        <h1 class="mt-5 mb-5">{{ actual_question.question }}</h1>

        <b-form @submit.prevent class="component-test-question">
            <b-form-group label="Odpoveď:" label-for="answer_input">
                <b-form-input id="answer_input" v-model.trim="user_answer" type="text" autofocus></b-form-input>
            </b-form-group>

            <b-row class="navigation-buttons">
                <b-col cols="6">
                    <img
                        v-if="this.$store.getters.test_question_index_difference !== 1 && this.$store.getters.test_question_index !== 0"
                        @click="changeQuestionIndex(false)"
                        :src="student_test_button_previous"
                        class="button-previous"
                        alt="image"
                        disabled
                    />
                </b-col>
                <b-col cols="6">
                    <img
                        v-if="$store.getters.test_question_index < $store.getters.test_questions.length - 1"
                        @click="changeQuestionIndex(true)"
                        :src="student_test_button_next"
                        class="ml-4 button-next"
                        alt="image"
                        disabled
                    />
                </b-col>
            </b-row>
        </b-form>

        <b-button v-if="testMaxQuestions" @click="sendTestAnswers()" variant="primary">ODOVZDAŤ TEST</b-button>
    </div>
</template>

<script>
import config from "@/config";
import moment from "moment";
import debounce from 'lodash.debounce';


import student_test_button_next from '@/assets/test_student/student_test_button_next.svg';
import student_test_button_previous from '@/assets/test_student/student_test_button_previous.svg';

export default {
    name: "PageTestRun",
    metaInfo() {
        return {
            title: 'Test | ',
        }
    },
    data() {
        return {
            student_test_button_next: student_test_button_next,
            student_test_button_previous: student_test_button_previous,

            actual_question: {},
            user_answer: '',

            start_time: '',
            end_time: '',
            difference: 0,
            actual_timeout: null,

            test_end: false,

            spinners: {
                load_data: false,
                post_data: false,
            },
        }
    },
    watch: {
        user_answer: debounce(function (value) {
            this.setStoreAnswer(value)
        }, 100),
        '$store.getters.test_question_index': function (value) {
            this.setNewQuestion(value)
        },
    },
    created() {
        this.setTestQuestions()
    },
    methods: {
        setTestQuestions() {
            if (this.$store.getters.test_time) {
                this.setUpTestTime(false)
                this.setNewQuestion(this.$store.getters.test_question_index)
            } else {
                this.getTestData()
            }
        },
        getTestData() {
            this.spinners.load_data = true
            this.axios.get(config.API_URL + '/test/startTest'
                + '?test_id=' + this.$route.params.test_id, config.HEADERS)
                .then((response) => {
                    let responseData = response.data
                    if (responseData.code === 200) {
                        let test_questions = this.restructureTestQuestions(responseData.data.questions)
                        // let test_questions = this.restructureTestQuestions(this.questions)
                        this.$store.commit('setTestQuestions', test_questions)

                        this.setUpTestTime(true, responseData.data.answering_time_number)
                        // this.setUpTestTime(true, 5)

                        this.setNewQuestion(this.$store.getters.test_question_index)
                    } else if (responseData.code === 401) {
            this.$store.dispatch('logoutUser', false)
        }
                    this.spinners.load_data = false
                }, (error) => {
                    console.warn(error);
                    this.spinners.load_data = false
                });

            // let test_questions = this.restructureTestQuestions(this.questions)
            // this.$store.commit('setTestQuestions', test_questions)
            // this.setUpTestTime(true, 1)
            // this.setNewQuestion(this.$store.getters.test_question_index)
        },
        restructureTestQuestions(questions) {
            let restructureQuestions = questions
            restructureQuestions.forEach(question => {
                question.answer = ''
            })
            return restructureQuestions
        },
        setUpTestTime(set_time, test_minutes) {
            this.start_time = new Date()
            if (set_time) {
                this.end_time = moment(new Date()).add(test_minutes, 'minutes')
                this.$store.commit('setTestTime', this.end_time)
            } else {
                this.end_time = this.$store.getters.test_time
            }

            let unixActualTime = moment(this.start_time).format('X')
            let unixEndTime = moment(this.end_time).format('X')
            this.difference = unixEndTime - unixActualTime

            this.countDownTimer()
        },
        countDownTimer() {
            if (this.difference > 0 && !this.test_end) {
                this.actual_timeout = setTimeout(() => {
                    this.difference -= 1
                    this.countDownTimer()
                }, 1000)
            } else if (this.difference === 0) {
                this.test_end = true
                this.sendTestAnswers()
            }
        },
        changeQuestionIndex(change) {
            if (change) {
                this.$store.commit('setTestQuestionIndex', change)
                this.$store.commit('setTestQuestionIndexLast', this.$store.getters.test_question_index)
            } else {
                if (this.$store.getters.test_question_index_difference === 0) {
                    this.$store.commit('setTestQuestionIndex', change)
                }
            }
        },
        setStoreAnswer(value) {
            this.$store.dispatch({ type: 'handleTestAnswer', id: this.actual_question.id, answer: value })
        },
        setNewQuestion(value) {
            this.actual_question = this.$store.getters.test_questions[value]
            this.user_answer = this.actual_question.answer
        },
        sendTestAnswers() {
            const questionData = new FormData()
            questionData.set('test_id', this.$route.params.test_id);
            this.$store.getters.test_questions.forEach(answer => {
                questionData.set('answers[' + [answer.id] + ']', answer.answer);
            })
            // for (let pair of questionData.entries()) {
            //     console.log(pair[0]+ ', ' + pair[1]);
            // }
            this.postTestAnswersData(questionData)
        },
        postTestAnswersData(question_answers) {
            this.spinners.post_data = true
            this.axios.post(config.API_URL + '/test/answerOnTestQuestion', question_answers, config.HEADERS)
                .then((response) => {
                    let responseData = response.data
                    if (responseData.code === 200) {
                        clearTimeout(this.actual_timeout);
                    } else if (responseData.code === 401) {
                        this.$store.dispatch('logoutUser', false)
                    }
                    this.$router.replace({ name: 'PageTestFinish' }, () => {})
                    this.$store.commit('resetTestData')

                    this.spinners.post_data = false
                }, (error) => {
                    console.warn(error);
                    this.spinners.post_data = false
                });
        },
    },
    computed: {
        formatDifference() {
            return moment(this.difference, 'X').format('mm:ss')
        },
        testQuestionCount() {
            return ((this.$store.getters.test_question_index + 1) + '/' + this.$store.getters.test_questions.length)
        },
        testMaxQuestions() {
            return (this.$store.getters.test_question_index + 1 === this.$store.getters.test_questions.length)
        },
    },
    beforeRouteLeave(to, from, next) {
        if (to.name === 'PageTestFinish') {
            next()
        } else {
            next(false)
        }
    },
}
</script>

<style lang="scss" scoped>
.page-test-run::v-deep {
    .test-head {
        display: flex;
        justify-content: space-around;

        .test-count {
            &__number {
                font-size: em(25);
            }
        }

        .test-time {
            &__timer {
                font-size: em(25);
                font-weight: $font-weight-bold;
            }
        }
    }

    .navigation-buttons {
        width: 100%;
        max-width: em(300);
        margin: em(50) 0;

        .button-previous, .button-next {
            cursor: pointer;
        }
    }

    @media (max-width: 991px) {
        .test-head {
            .test-count {
                &__number {
                    font-size: em(15);
                }
            }

            .test-time {
                &__timer {
                    font-size: em(15);
                }
            }
        }
    }
}
</style>