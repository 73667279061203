<template>
    <b-row class="component-test-result-head">
        <b-col cols="6">Dátum vytvorenia:</b-col>
        <b-col cols="6">{{ moment(test_info.created_at).format('L') }}</b-col>

        <b-col cols="6">Čas na test:</b-col>
        <b-col cols="6">{{ test_info.answering_time }}</b-col>

        <b-col cols="6">Počet slov:</b-col>
        <b-col cols="6">{{ test_info.question_count }}</b-col>

        <b-col cols="6">Skupina:</b-col>
        <b-col cols="6" v-if="test_info.study_group">{{ test_info.study_group.name }}</b-col>
    </b-row>
</template>

<script>
export default {
    name: "TestResultHead",
    props: {
        test_info: {
            type: Object,
            default() {
                return {}
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.component-test-result-head::v-deep {
    width: 100%;
    max-width: em(400);

    :nth-child(2n - 1) {
        text-align: left;
    }

    :nth-child(2n) {
        text-align: right;
    }
}
</style>