<template>
    <div class="component-unverified-email">
        <h2>POZOR!</h2>

        <p>Nemáte overenú emailovú adresu a niektoré funkcie nemusíte mať sprístupnené.</p>
        <p>Pre používanie webu bez obmedzení prosím overte svoju emailovú adresu.</p>

        <b-button @click="sendVerifyEmail()" variant="primary-lighter">
            <BeatLoader v-if="spinners.post_data" />
            <span v-else>ODOSLAŤ OVEROVACÍ EMAIL</span>
        </b-button>

        <ResponseError v-if="response_type.failed" :errors="response_type.failed" />
        <ResponseSuccess v-if="response_type.success" :success="response_type.success" />
    </div>
</template>

<script>
import config from "@/config";
import BeatLoader from 'vue-spinner/src/BeatLoader.vue';
import ResponseError from "@/components/ResponseError";
import ResponseSuccess from "@/components/ResponseSuccess";

export default {
    name: "UnverifiedEmail",
    components: {
        BeatLoader,
        ResponseError,
        ResponseSuccess,
    },
    data() {
        return {
            spinners: {
                post_data: false,
            },
            response_type: {
                success: '',
                failed: null,
            },
        }
    },
    methods: {
        sendVerifyEmail() {
            if (this.spinners.post_data) return false
            this.spinners.post_data = true
            this.axios.post(config.API_URL + '/auth/createVerifyEmail', {}, config.HEADERS)
                .then((response) => {
                    let responseData = response.data
                    if (responseData.code === 200) {
                        this.response_type.success = responseData.data.message
                    } else if (responseData.code === 401) {
                        this.$store.dispatch('logoutUser', false)
                    } else {
                        this.response_type.failed = responseData.errors
                    }
                    this.spinners.post_data = false
                }, (error) => {
                    console.warn(error)
                    this.spinners.post_data = false
                });
        },
    }
}
</script>

<style lang="scss" scoped>
.component-unverified-email::v-deep {
    padding: em(20);
    border-radius: em(4);
    background-color: $red;
    margin-bottom: em(30);

    .btn {
        min-width: em(310, 20);
    }

    .component-response-success {
        margin-bottom: 0;
    }
}
</style>