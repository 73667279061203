<template>
    <div class="page-study-group-students">
        <BeatLoader class="mt-5" v-show="spinners.load_data"/>

        <p v-if="!spinners.load_data && students.length === 0" class="mt-5">
            V skupine nie sú žiadni študenti.
        </p>

        <StudyGroupStudents
            v-show="!spinners.load_data && students.length > 0"
            :students="students"
            @update-students-list="getStudyGroupStudents()"
        />
    </div>
</template>

<script>
import config from "@/config";
import BeatLoader from 'vue-spinner/src/BeatLoader.vue';
import StudyGroupStudents from "@/components/study_group/StudyGroupStudents";

export default {
    name: "PageStudyGroupStudents",
    components: {
        BeatLoader,
        StudyGroupStudents,
    },
    data() {
        return {
            students: [],
            spinners: {
                load_data: false,
            },
        }
    },
    mounted() {
        this.getStudyGroupStudents()
    },
    methods: {
        getStudyGroupStudents() {
            this.spinners.load_data = true
            this.axios.get(config.API_URL + '/group/getGroupUsers' +
                '?slug=' + this.$route.params.slug, config.HEADERS)
                .then((response) => {
                    let responseData = response.data
                    if (responseData.code === 200) {
                        this.students = responseData.data.data
                    } else if (responseData.code === 401) {
                        this.$store.dispatch('logoutUser', false)
                    }
                    this.spinners.load_data = false
                }, (error) => {
                    console.warn(error);
                    this.spinners.load_data = false
                });
        }
    }
}
</script>

<style lang="scss" scoped>
.page-study-group-students::v-deep {
    //
}
</style>