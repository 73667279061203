<template>
    <b-form @submit.prevent="registrationNewUser()" class="component-form-registration">

        <FormUser
            :customer_data.sync="customer_data_user"
            ref="form_user"
        />

        <FormTeacher
            v-show="customer_data_user.user_type_id === 1"
            :customer_data.sync="customer_data_teacher"
            ref="form_teacher"
        />

        <FormStudent
            v-show="customer_data_user.user_type_id === 2"
            :customer_data.sync="customer_data_student"
            ref="form_student"
        />

        <b-form-group>
            <b-form-checkbox
                id="checkbox_vop"
                v-model="customer_data_checkbox.checkbox_vop"
                name="checkbox_vop"
                :value="true"
                :unchecked-value="false"
                :class="{ 'input--error': $v.customer_data_checkbox.checkbox_vop.$error }"
            >
                Súhlasím so
                <a href="#" class="confirm-conditions" target="_blank">Všeobecnými obchodnými podmienkami</a>
            </b-form-checkbox>

            <b-form-checkbox
                id="checkbox_sou"
                v-model="customer_data_checkbox.checkbox_sou"
                name="checkbox_sou"
                :value="true"
                :unchecked-value="false"
                :class="{ 'input--error': $v.customer_data_checkbox.checkbox_sou.$error }"
            >
                Súhlasím so
                <a href="#" class="confirm-conditions" target="_blank">Spracovaním osobných údajov</a>
            </b-form-checkbox>

            <div
                class="error-message-form"
                v-show="$v.customer_data_checkbox.checkbox_vop.$error || $v.customer_data_checkbox.checkbox_sou.$error"
            >
                <p>Bez súhlasu s podmienkami nie je registrácia možná.</p>
            </div>
        </b-form-group>

        <ResponseError v-if="response_type.failed" :errors="response_type.failed" />

        <b-button type="submit" variant="primary-lighter">
            <BeatLoader v-if="spinners.post_data" />
            <span v-else>REGISTROVAŤ SA</span>
        </b-button>
    </b-form>
</template>

<script>
import config from "@/config";
import FormUser from "@/components/login/FormUser";
import FormTeacher from "@/components/login/FormTeacher";
import FormStudent from "@/components/login/FormStudent";
import ResponseError from "@/components/ResponseError";
import BeatLoader from 'vue-spinner/src/BeatLoader.vue';

import { required, sameAs } from 'vuelidate/lib/validators'

export default {
    name: "RegistrationForm",
    components: {
        FormUser,
        FormTeacher,
        FormStudent,
        ResponseError,
        BeatLoader,
    },
    data() {
        return {
            customer_data_user: {
                user_type_id: 1,
                name: '',
                surname: '',
                email: '',
                password: '',
                password_confirmation: '',
            },
            customer_data_teacher: {
                country: '',
                school_type_id: null,
                school_name: '',
                phone: '',
            },
            customer_data_student: {
                parent_email: '',
            },
            customer_data_checkbox: {
                checkbox_vop: false,
                checkbox_sou: false,
            },

            spinners: {
                post_data: false,
            },
            response_type: {
                failed: null,
            },
        }
    },
    watch: {
        'customer_data_user.user_type_id': function () {
            this.clearFormData()
            this.resetFormsValidation()
        }
    },
    methods: {
        clearFormData() {
            // reset student data
            this.customer_data_student.parent_email = ''

            // reset teacher data
            this.customer_data_teacher.country = ''
            this.customer_data_teacher.school_type_id = null
            this.customer_data_teacher.school_name = ''
            this.customer_data_teacher.phone = ''

            this.customer_data_checkbox.checkbox_vop = false
            this.customer_data_checkbox.checkbox_sou = false

            this.response_type.failed = null
        },
        resetFormsValidation() {
            this.$v.$reset()
            this.$refs.form_user.$v.$reset()
            this.$refs.form_student.$v.$reset()
            this.$refs.form_teacher.$v.$reset()
        },
        checkValidationData() {
            this.$v.$touch()
            this.$refs.form_user.$v.$touch()
            if (this.customer_data_user.user_type_id === 1) this.$refs.form_teacher.$v.$touch()
            if (this.customer_data_user.user_type_id === 2) this.$refs.form_student.$v.$touch()

            if (this.$v.$invalid) {
                this.$nextTick(() => {
                    this.$scrollTo('.input--error')
                })
                return false
            }
            if (this.$refs.form_user.$v.$invalid) {
                this.$nextTick(() => {
                    this.$refs.form_user.$scrollTo('.input--error')
                })
                return false
            }
            if (this.customer_data_user.user_type_id === 1) {
                if (this.$refs.form_teacher.$v.$invalid) {
                    this.$nextTick(() => {
                        this.$refs.form_teacher.$scrollTo('.input--error')
                    })
                    return false
                }
            } else if (this.customer_data_user.user_type_id === 2) {
                if (this.$refs.form_student.$v.$invalid) {
                    this.$nextTick(() => {
                        this.$refs.form_student.$scrollTo('.input--error')
                    })
                    return false
                }
            }
            return true
        },
        mergeRegistrationData() {
            let merged
            if (this.customer_data_user.user_type_id === 1) {
                merged = {...this.customer_data_checkbox, ...this.customer_data_user, ...this.customer_data_teacher};
            } else if (this.customer_data_user.user_type_id === 2) {
                merged = {...this.customer_data_checkbox, ...this.customer_data_user, ...this.customer_data_student};
            }
            return merged
        },
        registrationNewUser() {
            if (!this.checkValidationData()) return false
            if (this.spinners.post_data) return false
            this.spinners.post_data = true
            this.response_type.failed = null
            let registrationData = this.mergeRegistrationData()
            this.postNewRegistration(registrationData)
        },
        postNewRegistration(registration_data) {
            this.axios.post(config.API_URL + '/auth/signup', registration_data, config.HEADERS)
                .then((response) => {
                    let responseData = response.data
                    if (responseData.code === 200) {
                        this.$store.dispatch('loginUser', responseData.data)
                    } else if (responseData.code === 401) {
                        this.$store.dispatch('logoutUser', false)
                    } else {
                        this.response_type.failed = responseData.errors
                    }
                    this.spinners.post_data = false
                }, (error) => {
                    console.warn(error)
                    this.spinners.post_data = false
                });
        },
    },
    validations: {
        customer_data_checkbox: {
            checkbox_vop: {
                required,
                sameAs: sameAs(() => true)
            },
            checkbox_sou: {
                required,
                sameAs: sameAs(() => true)
            },
        },
    },
}
</script>

<style lang="scss" scoped>
.component-form-registration::v-deep {
    .custom-checkbox {
        display: flex;
        cursor: pointer;

        .custom-control-input {
            margin: em(4) 0 0 em(5);
            cursor: pointer;
        }

        .custom-control-label {
            font-size: em(14);
            cursor: pointer;

            .confirm-conditions {
                &:hover {
                    color: $black;
                    text-decoration: underline;
                }
            }
        }
    }

    .btn {
        min-width: em(190, 16);
    }

    @media (max-width: 991px) {
        .custom-checkbox {
            .custom-control-label {
                font-size: em(15);
            }
        }
    }
}
</style>