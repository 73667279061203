<template>
    <div class="page-profile-test-results">
        <MainMenu :title="'Výsledky Testov'" />

        <div class="profile-test-results-container container">
            <StudentTestResultsList
                :test_list="test_list"
                :records="pagination.records"
                :load_data="spinners.load_data"
                :results_explanation="true"
            />
            <VuePagination
                :per_page="pagination.per_page"
                :actual_page.sync="pagination.actual_page"
                :records="pagination.records"
                :load_data="spinners.load_data"
            />
        </div>

        <BodyImageBackground />
    </div>
</template>

<script>
import config from "@/config";
import MainMenu from "@/components/MainMenu";
import BodyImageBackground from "@/components/BodyImageBackground";
import StudentTestResultsList from "@/components/profile/StudentTestResultsList";
import VuePagination from "@/components/VuePagination";

export default {
    name: "PageProfileTestResults",
    metaInfo() {
        return {
            title: 'Výsledky testov | Profil | ',
        }
    },
    components: {
        StudentTestResultsList,
        MainMenu,
        BodyImageBackground,
        VuePagination,
    },
    data() {
        return {
            pagination: {
                per_page: 10,
                actual_page: 1,
                records: 0,
            },
            test_list: [],
            spinners: {
                load_data: false,
            },
        }
    },
    watch: {
        '$route.query.strana': function () {
            this.setActualPage()
        },
    },
    created() {
        this.setActualPage(this.$route.query.strana)
    },
    methods: {
        setActualPage() {
            this.$route.query.strana ? this.pagination.actual_page = Number(this.$route.query.strana) : this.pagination.actual_page = 1
            this.getStudentTestsResults()
        },
        getStudentTestsResults() {
            this.spinners.load_data = true
            this.axios.get(config.API_URL + '/test/getTestList'
                + '?user_slug=' + this.$store.getters.user.slug
                + '&perPage=' + this.pagination.per_page
                + '&page=' + this.pagination.actual_page
                + '&orderBy=' + 'created_at'
                + '&ordering=' + 'DESC', config.HEADERS)
                .then((response) => {
                    let responseData = response.data
                    if (responseData.code === 200) {
                        this.test_list = responseData.data.data
                        this.pagination.records = responseData.data.total
                    } else if (responseData.code === 401) {
                        this.$store.dispatch('logoutUser', false)
                    }
                    this.spinners.load_data = false
                }, (error) => {
                    console.warn(error);
                    this.spinners.load_data = false
                });
        },
    }
}
</script>

<style lang="scss" scoped>
.page-profile-test-results::v-deep {
    @extend %page-position-bg-image;

    .profile-test-results-container {
        @extend %container-position-bg-image;
    }
}
</style>