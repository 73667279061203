<template>
    <div class="page-sign-up">
        <LoginMenu />

        <div class="sign-up-container container">
            <b-row class="registration-wrapper">

                <b-col
                    cols="12"
                    lg="4"
                    offset-lg="1"
                    order="2"
                    order-lg="1"
                    class="registration-wrapper__body"
                >
                    <h1 class="registration-title">Registrácia</h1>
                    <img :src="asset_1" class="asset_1 m-display-none" alt="image" />

                    <RegistrationForm />
                </b-col>

                <b-col
                    cols="12"
                    lg="6"
                    order="1"
                    order-lg="2"
                    class="registration-wrapper__image"
                >
                    <img :src="asset_2" class="asset_2" alt="image" />
                </b-col>

            </b-row>
        </div>
    </div>
</template>

<script>
import registration_asset_1 from "@/assets/login/registration_asset_1.svg";
import registration_main_image from "@/assets/login/registration_main_image.svg";

import LoginMenu from "@/components/login/LoginMenu";
import RegistrationForm from "@/components/login/RegistrationForm";

export default {
    name: "PageRegistration",
    metaInfo() {
        return {
            title: 'Registrácia | ',
        }
    },
    components: {
        LoginMenu,
        RegistrationForm,
    },
    data() {
        return {
            asset_1: registration_asset_1,
            asset_2: registration_main_image,
        }
    },
}
</script>

<style lang="scss" scoped>
.page-sign-up::v-deep {
    background-color: $body-gray;
    min-height: 100vh;
    padding-bottom: em(70);

    .registration-wrapper {
        margin: em(20) 0;

        &__body {
            .registration-title {
                position: relative;
                margin: 10px 0;
                z-index: 1;
            }

            .asset_1 {
                margin-top: em(-40);
                width: 100%;
                max-width: em(170);
            }
        }

        &__image {
            .asset_2 {
                width: 100%;
            }
        }
    }

    @media (max-width: 991px) {
        .registration-wrapper {
            margin-top: em(10);

            &__image {
                margin-bottom: em(10);

                .asset_2 {
                    width: 60%;
                    max-width: em(500);
                }
            }
        }
    }
}
</style>