<template>
    <div class="page-study-group-settings">
        <h1 class="mb-3">{{ $store.state.study_group.name }}</h1>
        <p>Kód skupiny: {{ $store.state.study_group.code }}</p>

        <b-button @click="postStudyGroupShare()" class="share-button" variant="primary-lighter">
            <BeatLoader v-if="spinners.share_group" />
            <span v-else>Pozvánka do skupiny</span>
        </b-button>
        <ResponseSuccess v-if="response_type_share.success" :success="response_type_share.success" />
        <ResponseError v-if="response_type_share.failed" :errors="response_type_share.failed" />

        <b-form class="form-settings">
            <b-form-group label="Názov skupiny*" label-for="name_input">
                <b-form-input id="name_input" v-model="group_data.name" type="text"></b-form-input>
                <div class="error-message-form" v-show="$v.group_data.name.$error">
                    <p v-show="!$v.group_data.name.required">Zadajte názov skupiny.</p>
                </div>
            </b-form-group>
            <b-form-group label="Maximálny počet členov v skupine*" label-for="user_count_input">
                <b-form-input id="user_count_input" v-model="group_data.user_count" type="number"></b-form-input>
                <div class="error-message-form" v-show="$v.group_data.user_count.$error">
                    <p v-show="!$v.group_data.user_count.required || !$v.group_data.user_count.numeric">Zadajte maximálny počet členov v skupine.</p>
                </div>
            </b-form-group>

            <b-button @click="updateStudyGroup()" class="save-button" variant="primary-lighter">
                <BeatLoader v-if="spinners.post_data" />
                <span v-else>ULOŽIŤ</span>
            </b-button>
        </b-form>

        <ResponseSuccess v-if="response_type.success" :success="response_type.success" />
        <ResponseError v-if="response_type.failed" :errors="response_type.failed" />
    </div>
</template>

<script>
import config from "@/config";
import store from '@/store'
import BeatLoader from 'vue-spinner/src/BeatLoader.vue';
import ResponseSuccess from "@/components/ResponseSuccess";
import ResponseError from "@/components/ResponseError";
import { required, numeric } from "vuelidate/lib/validators";

export default {
    name: "PageStudyGroupSettings",
    components: {
        BeatLoader,
        ResponseSuccess,
        ResponseError,
    },
    beforeRouteEnter(to, from, next) {
        const have_permission = store.getters.check_permission('manage own groups')
        have_permission ? next() : next({ name: 'PageHome' })
    },
    data() {
        return {
            group_data: {
                name: '',
                user_count: 0,
            },
            spinners: {
                post_data: false,
                share_group: false,
            },
            response_type: {
                success: '',
                failed: null,
            },
            response_type_share: {
                success: '',
                failed: null,
            },
        }
    },
    mounted() {
        this.group_data.name = this.$store.state.study_group.name
        this.group_data.user_count = this.$store.state.study_group.user_count
    },
    methods: {
        updateStudyGroup() {
            this.response_type.success  = ''
            this.response_type.failed  = {}
            this.$v.$touch()
            if (this.$v.$invalid) return false
            this.postUpdateStudyGroupData(this.group_data)
        },
        postUpdateStudyGroupData(group_data) {
            this.spinners.post_data = true
            this.axios.post(config.API_URL + '/group/' + this.$route.params.slug, group_data, config.HEADERS)
                .then((response) => {
                    let responseData = response.data
                    if (responseData.code === 200) {
                        this.response_type.success  = responseData.message
                        let param_slug = Object.assign({}, this.$route.params);
                        param_slug.slug = responseData.data.slug
                        this.$router.replace({ 'params': param_slug }, () => {});
                        this.$store.commit('setStudyGroup', responseData.data)
                    } else if (responseData.code === 401) {
                        this.$store.dispatch('logoutUser', false)
                    } else {
                        this.response_type.failed = responseData.errors
                    }
                    this.spinners.post_data = false
                }, (error) => {
                    console.warn(error)
                    this.spinners.post_data = false
                });
        },
        postStudyGroupShare() {
            this.response_type_share.success = ''
            this.response_type_share.failed = {}
            if (this.spinners.share_group) return false
            this.spinners.share_group = true
            this.axios.post(config.API_URL + '/group/sendEmail/' + this.$route.params.slug, {}, config.HEADERS)
                .then((response) => {
                    let responseData = response.data
                    if (responseData.code === 200) {
                        this.response_type_share.success  = responseData.message
                    } else if (responseData.code === 401) {
                        this.$store.dispatch('logoutUser', false)
                    } else {
                        this.response_type_share.failed = responseData.errors
                    }
                    this.spinners.share_group = false
                }, (error) => {
                    console.warn(error)
                    this.spinners.share_group = false
                });
        },
    },
    validations: {
        group_data: {
            name: {
                required,
            },
            user_count: {
                required,
                numeric,
            },
        },
    }
}
</script>

<style lang="scss" scoped>
.page-study-group-settings::v-deep {
    .share-button {
        min-width: em(250, 20);
    }
    .form-settings {
        margin-top: em(50);

        .save-button {
            min-width: em(200, 20);
        }
    }
}
</style>