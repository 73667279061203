import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

/**
 * Vue Bootstrap
 */
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import '@/css/base.scss'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import Axios from 'axios'
Vue.prototype.axios = Axios;

/**
 * Vuelidate
 */
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

/**
 * VueScrollTo
 */
import VueScrollTo from 'vue-scrollto';
Vue.use(VueScrollTo, {
  container: "body",
  duration: 100,
  force: false,
  easing: "linear",
  onStart: false,
  onDone: false,
  onCancel: false,
});

/**
 * Vue moment
 */
import moment from 'moment';
moment.locale('sk');
Vue.prototype.moment = moment;

/**
 * Vue Pagination 2
 */
import Pagination from 'vue-pagination-2';
Vue.component('pagination', Pagination);

/**
 * Vue Meta
 */
import VueMeta from 'vue-meta'
Vue.use(VueMeta)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
