<template>
    <div class="component-study-groups-list">

        <div class="study-groups-menu">
            <b-button v-if="$store.getters.check_permission('join groups')" v-b-modal.study-groups-modal-add-to-group variant="primary-dark">
                PRIDAŤ SA DO SKUPINY
            </b-button>
<!--            <a href="#">VŠETKY SKUPINY</a>-->
        </div>

        <div v-if="!load_data && records === 0 && study_groups.length === 0" class="mt-5 mb-5">
            <div v-if="$store.getters.user.user_type_id === 1">
                <p><strong>Zatiaľ ste ešte nevytvorili žiadnu skupinu.</strong></p>
                <b-button @click="$router.push({ name: 'PageStudyGroupCreate' }, () => {})" variant="primary-lighter">
                    VYTVORIŤ SKUPINU
                </b-button>
            </div>
            <div v-else>
                <p><strong>Zatiaľ nie ste pridaný do žiadnej skupiny.</strong></p>
            </div>
        </div>
        <div v-else class="study-group-list">
            <div class="group-list-head">
                <b-row>
                    <b-col cols="10" lg="6" class="study-group-title">Názov skupiny</b-col>
                    <b-col cols="2" class="m-display-none">Kód skupiny</b-col>
                    <b-col cols="1" class="m-display-none">Jazyk výuky</b-col>
                    <b-col cols="1" class="m-display-none">Cieľový jazyk</b-col>
                    <b-col cols="1" class="m-display-none">Členovia</b-col>
                    <b-col cols="2" lg="1"></b-col>
                </b-row>
            </div>
            <StudyGroupsListCard v-for="group in study_groups" :key="group.id" :study_group="group" />
        </div>

<!--        <div class="all-subjects">-->
<!--            <a href="#">VŠETKY PREDMETY</a>-->
<!--        </div>-->
    </div>
</template>

<script>
import StudyGroupsListCard from "@/components/home/StudyGroupsListCard";

export default {
    name: "StudyGroupsList",
    components: {
        StudyGroupsListCard,
    },
    props: {
        study_groups: {
            type: Array,
            default() {
                return []
            }
        },
        records: {
            type: Number,
            default: 0
        },
        load_data: {
            type: Boolean,
            default: false
        },
    },
}
</script>

<style lang="scss" scoped>
.component-study-groups-list::v-deep {
    margin-bottom: em(30);

    .study-groups-menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .study-group-list {
        .group-list-head {
            margin-top: em(20);
            font-size: em(12);

            .study-group-title {
                text-align: left;
                padding-left: em(40, 12);
            }
        }

        .single-study-groups-list-card {
            background-color: $green;

            &:hover {
                background-color: $green-lighter;
            }
        }

        .single-study-groups-list-card:nth-child(2n) {
            background-color: $primary;

            &:hover {
                background-color: $primary-lighter;
            }
        }
    }

    .all-subjects {
        text-align: left;
    }

    @media (max-width: 991px) {
        .study-groups-menu {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
</style>