<template>
    <div class="page-study-group-create">
        <MainMenu :title="'Nová skupina'" />

        <div class="study-group-create-container container">
            <h1>Vytvorenie novej skupiny</h1>

            <b-form>
                <b-row class="select-language">
                    <b-col cols="12" class="select-language__col">
                        <b-form-group label="Názov skupiny*" label-for="study_group_name" :class="{ 'input--error': $v.name.$error }">
                            <b-form-input id="study_group_name" v-model="name" type="text"></b-form-input>
                            <div class="error-message-form" v-show="$v.name.$error">
                                <p v-show="!$v.name.required">Prosím zadajte meno skupiny.</p>
                                <div v-if="!$v.name.minLength">Meno skupiny musí obsahovať najmenej {{ $v.name.$params.minLength.min }} znakov.</div>
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" class="select-language__col">
                        <b-form-group label="Maximálny počet členov v skupine*" label-for="study_group_user_count" :class="{ 'input--error': $v.user_count.$error }">
                            <b-form-input id="study_group_user_count" v-model="user_count" type="number"></b-form-input>
                            <div class="error-message-form" v-show="$v.user_count.$error">
                                <p v-show="!$v.user_count.required">Prosím zadajte počet členov skupiny.</p>
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" lg="6" class="select-language__col">
                        <b-form-group label="Jazyk výuky*" label-for="select_lang_from" :class="{ 'input--error': $v.lang_from_id.$error }">
                            <b-form-select id="select_lang_from" v-model="lang_from_id" :options="languages" class="input_select_type"></b-form-select>
                            <div class="error-message-form" v-show="$v.lang_from_id.$error">
                                <p v-show="!$v.lang_from_id.required">Prosím vyberte jazyk výuky.</p>
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" lg="6" class="select-language__col">
                        <b-form-group label="Cieľový jazyk*" label-for="select_lang_to" :class="{ 'input--error': $v.lang_to_id.$error }">
                            <b-form-select id="select_lang_to" v-model="lang_to_id" :options="languages_filtered" class="input_select_type" :disabled="lang_from_id === null"></b-form-select>
                            <div class="error-message-form" v-show="$v.lang_to_id.$error">
                                <p v-show="!$v.lang_to_id.required">Prosím vyberte cieľový jazyk.</p>
                            </div>
                        </b-form-group>
                    </b-col>
                </b-row>

                <StudyGroupPackagesList
                    v-show="lang_from_id && lang_to_id"
                    :packages_list.sync="packages_list"
                    :packages_selected.sync="packages_selected"
                    :lang_from="lang_from_id"
                    :lang_to="lang_to_id"
                    :default_button="'remove'"
                    @remove-package="removePackage($event)"
                    @clear-success-update-message="clearResponseMessage()"
                />

                <ModalPackageAdd
                    :packages_data="packages_data"
                    :pagination="pagination"
                    :package_search.sync="package_search"
                    :load_data="spinners.load_data"
                    :packages_selected="packages_selected"
                    @reset-actual-page="resetActualPage()"
                    @update-packages="updatePackages($event)"
                />

                <ResponseError v-if="response_type.failed" :errors="response_type.failed" />

                <b-button @click="createNewGroup()" variant="primary-lighter" class="btn-send">
                    <BeatLoader v-if="spinners.post_data" />
                    <span v-else>VYTVORIŤ SKUPINU</span>
                </b-button>
            </b-form>
        </div>

        <BodyImageBackground />
    </div>
</template>

<script>
import config from "@/config";
import store from '@/store';

import button_add_package from "@/assets/button_add_package.svg";

import MainMenu from "@/components/MainMenu";
import StudyGroupPackagesList from "@/components/study_group/StudyGroupPackagesList";
import ModalPackageAdd from "@/components/ModalPackageAdd";
import ResponseError from "@/components/ResponseError";
import BodyImageBackground from "@/components/BodyImageBackground";
import BeatLoader from 'vue-spinner/src/BeatLoader.vue';

import { required, minLength } from "vuelidate/lib/validators";

export default {
    name: "PageStudyGroupCreate",
    metaInfo() {
        return {
            title: 'Vytvoriť skupinu | ',
        }
    },
    components: {
        MainMenu,
        StudyGroupPackagesList,
        ModalPackageAdd,
        ResponseError,
        BodyImageBackground,
        BeatLoader,
    },
    data() {
        return {
            button_add_package: button_add_package,

            name: '',
            user_count: 10,
            lang_from_id: null,
            lang_to_id: null,

            languages: [],
            languages_filtered: [],

            packages_data: [],
            package_search: '',
            pagination: {
                per_page: 10,
                actual_page: 1,
                records: 0,
                query_change: false,
            },
            packages_list: [],
            packages_selected: [],

            spinners: {
                load_data: false,
                post_data: false,
            },
            response_type: {
                failed: null,
            },
        }
    },
    watch: {
        lang_from_id(lang_id) {
            this.resetSelectedData(lang_id)
        },
        lang_to_id() {
            if (this.lang_to_id) this.getAllPackages()
        },
        'pagination.actual_page': function () {
            this.getAllPackages()
        },
        package_search() {
            this.getAllPackages()
        },
        packages_list() {
            this.packages_selected = this.packages_list.map(obj => obj.id)
        },
    },
    mounted() {
        this.getLanguageList()
    },
    beforeRouteEnter(to, from, next) {
        const have_permission = store.getters.check_permission('manage own groups')
        have_permission ? next() : next({ name: 'PageHome' })
    },
    methods: {
        getLanguageList() {
            this.axios.get(config.API_URL + '/packages/getLanguageList', config.HEADERS)
                .then((response) => {
                    let responseData = response.data
                    if (responseData.code === 200) {
                        let languages = []
                        responseData.data.forEach(el => {
                            let language = {}
                            language.value = el.id
                            language.text = el.full_name
                            languages.push(language)
                        })
                        this.languages = languages
                    } else if (responseData.code === 401) {
                        this.$store.dispatch('logoutUser', false)
                    }
                }, (error) => {
                    console.warn(error)
                });
        },
        filterLanguages(lang_id) {
            this.languages_filtered = this.languages.filter(obj => obj.value !== lang_id)
        },
        getAllPackages() {
            this.spinners.load_data = true
            this.axios.get(config.API_URL + '/packages/getAll'
                + '?body=' + this.package_search
                + '&perPage=' + this.pagination.per_page
                + '&page=' + this.pagination.actual_page
                + '&lang_from_id=' + this.lang_from_id
                + '&lang_to_id=' + this.lang_to_id, config.HEADERS)
                .then((response) => {
                    let responseData = response.data
                    if (responseData.code === 200) {
                        this.packages_data = responseData.data.data
                        this.pagination.records = responseData.data.total
                    } else if (responseData.code === 401) {
                        this.$store.dispatch('logoutUser', false)
                    }
                    this.spinners.load_data = false
                }, (error) => {
                    console.warn(error)
                    this.spinners.load_data = false
                });
        },
        resetActualPage() {
            this.pagination.actual_page = 1
        },
        resetSelectedData(lang_id) {
            this.lang_to_id = null
            this.pagination.actual_page = 1
            this.packages_data = []
            this.packages_list = []
            this.packages_selected = []
            this.filterLanguages(lang_id)
        },
        updatePackages(new_package) {
            let existPackage = this.packages_list.some(obj => obj.id === new_package.id);
            existPackage ? this.removePackage(new_package.id) : this.packages_list.push(new_package)
        },
        removePackage(old_package) {
            this.packages_list = this.packages_list.filter(obj => obj.id !== old_package)
        },
        clearResponseMessage() {
            this.response_type.failed = null
        },
        createNewGroup() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.$nextTick(() => {
                    this.$scrollTo('.input--error')
                })
                return false
            }

            const newGroupData = new FormData()
            newGroupData.set('name', this.name);
            newGroupData.set('lang_from_id', this.lang_from_id);
            newGroupData.set('lang_to_id', this.lang_to_id);
            newGroupData.set('user_count', this.user_count);
            this.packages_selected.forEach((package_id, index) => {
                newGroupData.set('packages[' + [index] + ']', package_id);
            })
            // for (let pair of newGroupData.entries()) {
            //     console.log(pair[0]+ ', ' + pair[1]);
            // }
            this.postNewGroupData(newGroupData)
        },
        postNewGroupData(data) {
            this.spinners.post_data = true
            this.axios.post(config.API_URL + '/group', data, config.HEADERS)
                .then((response) => {
                    let responseData = response.data
                    if (responseData.code === 200) {
                        this.$router.replace({ name: 'PageStudyGroupShare', params: { slug: responseData.data.slug }, query: { group_code: responseData.data.code } }, () => {})
                    } else if (responseData.code === 401) {
                        this.$store.dispatch('logoutUser', false)
                    } else {
                        this.response_type.failed = responseData.errors
                    }
                    this.spinners.post_data = false
                }, (error) => {
                    console.warn(error)
                    this.spinners.post_data = false
                });
        },
    },
    validations: {
        name: {
            required,
            minLength: minLength(5)
        },
        user_count: {
            required,
        },
        lang_from_id: {
            required,
        },
        lang_to_id: {
            required,
        },
    },
}
</script>

<style lang="scss" scoped>
.page-study-group-create::v-deep {
    @extend %page-position-bg-image;

    .study-group-create-container {
        @extend %container-position-bg-image;
        width: 100%;
        max-width: em(800);

        h1 {
            margin-bottom: em(50);
        }

        .select-language {
            width: 100%;

            &__col {
                @extend %flex-column-center;
            }
        }

        .btn-send {
            min-width: em(250, 20);
        }
    }

    @media (max-width: 991px) {
        .study-group-create-container {
            h1 {
                margin-bottom: em(20);
            }
        }
    }
}
</style>