<template>
    <div class="component-form-student">
        <b-form-group label="Email rodiča*" label-for="student_parent_email" :class="{ 'input--error': $v.customer_data.parent_email.$error }">
            <b-form-input id="student_parent_email" v-model="customer_data.parent_email" type="email"></b-form-input>
            <div class="error-message-form" v-show="$v.customer_data.parent_email.$error">
                <p v-show="!$v.customer_data.parent_email.required || !$v.customer_data.parent_email.email">Prosím zadajte platný email rodiča.</p>
            </div>
        </b-form-group>
    </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";

export default {
    name: "FormStudent",
    props: {
        customer_data: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    validations: {
        customer_data: {
            parent_email: {
                required,
                email,
            },
        },
    },
}
</script>

<style lang="scss" scoped>
.component-form-student::v-deep {
    @extend %flex-column-center;
    width: 100%;
}
</style>