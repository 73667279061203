<template>
    <div class="single-project-list-card">
        <div>
            <h2 class="project-card-title">{{ project_card.name }}</h2>
            <b-row class="project-card-data">
                <b-col cols="4" lg="3" class="project-card-data__image">
                    <img @click="startStudy(project_card.slug)" :src="project_play_purple" class="project-play" alt="image" />
                </b-col>
                <b-col cols="8" lg="8" class="project-card-data__item">
                    <div>
                        <div class="value-label">Absolvované:</div>
                        <div>{{ project_card.user_statistics.showed_words }}/{{ project_card.all_words_count }}</div>
                    </div>
                    <div>
                        <div class="value-label">Zopakovať:</div>
                        <div>{{ project_card.user_statistics.showed_words - project_card.user_statistics.words_for_repeat }}/{{ project_card.user_statistics.showed_words }}</div>
                    </div>
                </b-col>
                <b-col cols="12" class="project-card-data__last-studied">
                    <p v-if="project_card.user_statistics.last_studied">
                        <span class="m-display-none">Posledné spustenie:</span>
                        {{ moment(project_card.user_statistics.last_studied).format("LL") }}
                    </p>
                    <p v-else><em>Tento balík ste zatiaľ neštudovali</em></p>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import project_play_purple from "@/assets/project_play_purple.svg";

export default {
    name: "ProjectListCard",
    props: {
        project_card: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    data() {
        return {
            project_play_purple: project_play_purple
        }
    },
    methods: {
        startStudy(package_slug) {
            this.$router.push({ name: 'PageLearning', params: { slug: package_slug }}, () => {})
        },
    }
}
</script>

<style lang="scss" scoped>
.single-project-list-card::v-deep {
    position: relative;
    background-color: $body-gray;
    border-radius: em(10);
    cursor: pointer;
    padding: em(20) 0 em(10);

    .project-card-title {
        font-size: em(20);
        margin-bottom: em(10);
        font-weight: $font-weight-bold;
        min-height: em(40, 20);
        padding: 0 em(10);

        @include webkit-line-clamp();
    }

    .project-card-data {
        width: 100%;
        margin: 0;

        > div {
            padding: 0;
        }

        &__image {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__item {
            > div {
                display: flex;
                align-items: center;
                flex-direction: row;
                justify-content: space-between;
                padding-right: 10px;
            }
        }

        &__last-studied {
            font-size: em(12);
            margin-top: em(5);
        }
    }

    @media (max-width: 1200px) {
        .project-card-data {
            &__image {
                img {
                    width: em(40);
                }
            }

            &__item {
                > div {
                    align-items: flex-start;
                    flex-direction: column;
                    justify-content: space-between;
                }

                .value-label {
                    font-size: em(13);
                }
            }
        }
    }
}
</style>