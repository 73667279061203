<template>
    <div class="component-student-test-results-list">
        <div class="test-results-header m-display-none">
            <b-row>
                <b-col cols="3">Názov skupiny</b-col>
                <b-col cols="2">Číslo testu</b-col>
                <b-col cols="1">Čas na test</b-col>
                <b-col cols="1">Otázky</b-col>
                <b-col cols="2">Test odovzdaný</b-col>
                <b-col cols="1">Kontrola učiteľom</b-col>
                <b-col cols="2"></b-col>
            </b-row>
        </div>

        <div v-if="!load_data && records === 0 && test_list.length === 0" class="mt-5 mb-5">
            <strong>Zatiaľ nemáte pridelené žiadne testy.</strong>
        </div>
        <StudentTestResultsListCard
            v-else
            v-for="test in test_list"
            :key="test.id"
            :test="test"
        />

        <div v-if="results_explanation" class="test-results-explanation">
            <div class="test-results-explanation__success"><em>Test bol vypracovaný</em></div>
            <div class="test-results-explanation__failed"><em>Test nebol vypracovaný</em></div>
        </div>
    </div>
</template>

<script>
import StudentTestResultsListCard from "@/components/profile/StudentTestResultsListCard";

export default {
    name: "StudentTestList",
    components: {
        StudentTestResultsListCard,
    },
    props: {
        test_list: {
            type: Array,
            default() {
                return []
            }
        },
        records: {
            type: Number,
            default: 0
        },
        load_data: {
            type: Boolean,
            default: false
        },
        results_explanation: {
            type: Boolean,
            default: true
        },
    },
}
</script>

<style lang="scss" scoped>
.component-student-test-results-list::v-deep {
    .test-results-header {
        font-size: em(10);
    }

    .test-results-explanation {
        display: flex;
        justify-content: space-evenly;
        position: relative;
        margin-top: em(20);

        &__success, &__failed {
            display: flex;
            align-items: center;
        }

        &__success:before, &__failed:before {
            content: '';
            display: block;
            width: em(20);
            height: em(20);
            position: relative;
            border-radius: em(5);
            margin-right: em(10);
        }

        &__success:before {
            background-color: $green;
        }

        &__failed:before {
            background-color: $red;
        }
    }

    @media (max-width: 991px) {
        .test-results-explanation {
            font-size: em(10);
        }
    }
}
</style>