<template>
    <div class="page-student-test">
        <MainMenu :title="'Test'" />

        <div class="student-test-container container">
            <router-view></router-view>
        </div>

        <BodyImageBackground />
    </div>
</template>

<script>
import MainMenu from "@/components/MainMenu";
import BodyImageBackground from "@/components/BodyImageBackground";

export default {
    name: "PageStudentTest",
    metaInfo() {
        return {
            title: 'Test | ',
        }
    },
    components: {
        MainMenu,
        BodyImageBackground,
    },
}
</script>

<style lang="scss" scoped>
.page-student-test::v-deep {
    @extend %page-position-bg-image;

    .student-test-container {
        @extend %container-position-bg-image;
    }
}
</style>