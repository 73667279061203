<template>
    <div class="page-home">
        <MainMenu :title="'Domov'" />

        <div class="home-container container">
            <UnverifiedEmail v-if="$store.getters.user && !$store.getters.user.email_verified_at" />

            <StudyGroups/>

            <NotRunTests
                v-if="$store.getters.user.user_type_id === 2"
            />

            <ProjectList />
        </div>

        <BodyImageBackground />
    </div>
</template>

<script>
import MainMenu from '@/components/MainMenu.vue';
import ProjectList from "@/components/home/ProjectList";
import StudyGroups from "@/components/home/StudyGroups";
import NotRunTests from "@/components/home/NotRunTests";
import BodyImageBackground from "@/components/BodyImageBackground";
import UnverifiedEmail from "../components/home/UnverifiedEmail";

export default {
    name: 'PageHome',
    metaInfo() {
        return {
            title: 'Domov | ',
            meta: [
                // { vmid: 'description', name: 'description', content: this.$store.getters.settings.meta_description_blog },
                // { vmid: 'og:description', name: 'og:description', content: this.$store.getters.settings.meta_description_blog },
                // { vmid: 'og:title', name: 'og:title', content: 'eBrick Blog' },
                // { vmid: 'og:type', name:'og:type', content: 'blog' },
            ]
        }
    },
    components: {
        UnverifiedEmail,
        MainMenu,
        StudyGroups,
        NotRunTests,
        ProjectList,
        BodyImageBackground,
    },
}
</script>

<style lang="scss" scoped>
.page-home::v-deep {
    @extend %page-position-bg-image;

    .home-container {
        @extend %container-position-bg-image;
    }
}
</style>
