<template>
    <div class="component-study-group-tests-list-student">
        <b-row class="tests-group-head">
            <b-col cols="3" lg="2">Číslo testu</b-col>
            <b-col cols="2" class="m-display-none">Dátum vytvorenia</b-col>
            <b-col cols="3" lg="2">Stav</b-col>
            <b-col cols="2" lg="1">Čas</b-col>
            <b-col cols="2" lg="1">Otázky</b-col>
            <b-col cols="2" class="m-display-none">Kontrola učiteľom</b-col>
            <b-col cols="2"></b-col>
            <b-col cols="12" >
                <hr class="test-hr">
            </b-col>
        </b-row>

        <b-row v-for="test in test_list" :key="test.id" class="test-group-student-body">
            <b-col cols="3" lg="2">{{ test.test_number }}</b-col>
            <b-col cols="2" class="m-display-none">{{ moment(test.created_at).format("L") }}</b-col>
            <b-col cols="3" lg="2" class="test-state">
                <div v-if="test.state === 1">odovzdaný</div>
                <div class="not-submitted" v-else>neodovzdaný</div>
            </b-col>
            <b-col cols="2" lg="1">{{ test.answering_time_number }} min</b-col>
            <b-col cols="2" lg="1">
                <span v-if="test.state === 1">{{ test.right_answers_count }}/{{ test.question_count }}</span>
                <span v-else>{{ test.question_count }}</span>
            </b-col>
            <b-col cols="2" class="m-display-none">
                <div v-if="test.teacher_control === 1">áno</div>
                <div v-else>nie</div>
            </b-col>
            <b-col cols="2" class="run-test">
                <b-button v-if="test.state === 0" @click="startTest(test.test_number, test.id)" variant="danger">Spustiť test</b-button>
                <b-button v-else @click="goTestCheck(test.test_number, test.id)" variant="primary">Výsledky</b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>

export default {
    name: "StudentTestResultsListCard",
    props: {
        test_list: {
            type: Array,
            default() {
                return []
            }
        },
    },
    methods: {
        startTest(test_number, test_id) {
            this.$router.push({ name: 'PageTestStart', params: { test_number: test_number, test_id: test_id }})
        },
        goTestCheck(test_number, test_id) {
            this.$router.push({ name: 'PageTestControl', params: { test_number: test_number }, query: { test: test_id } }, () => {})
        },
    },
}
</script>

<style lang="scss" scoped>
.component-study-group-tests-list-student::v-deep {

    .tests-group-head {
        .test-hr {
            margin-top: 0;
            margin-bottom: em(10);
        }
    }

    .test-group-student-body {
        > div {
            margin-bottom: em(15);
        }

        .test-state {
            .not-submitted {
                color: white;
                border-radius: em(4);
                background-color: $red;
            }
        }

        .run-test {
            cursor: pointer;

            .btn {
                margin: 0;
                padding: 0 em(10);
                font-size: 1rem;
                width: 100%;
            }
        }
    }

    @media (max-width: 991px) {
        .tests-group-head {
            font-size: em(10);
        }

        .test-group-student-body {
            font-size: em(12);

            > div {
                padding: 0;
            }

            .run-test {
                .btn {
                    font-size: em(12);
                }
            }
        }
    }
}
</style>