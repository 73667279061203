<template>
    <b-modal id="modal-package-add" size="lg" :no-close-on-backdrop="true">
        <template #modal-title>Výber balíkov</template>

        <div class="package-add-body">
            <b-form @submit.prevent>
                <b-form-group label="Vyhľadávanie" label-for="package_search_input">
                    <b-form-input id="package_search_input" v-model="package_search_term" type="text"></b-form-input>
                </b-form-group>
            </b-form>

            <p v-show="!load_data && packages_data.length === 0" class="mt-3 mb-3">Pre túto kombináciu jazykov neboli vytvorené žiadne balíky.</p>
            <div v-if="packages_data.length > 0" class="package-list">
                <PackageCard
                    v-for="card in packages_data"
                    :key="card.id"
                    :package_card="card"
                    :button_type="buttonTypes(card.id)"
                    @update-packages="updatePackages($event)"
                />
            </div>
            <VuePagination
                id="paginator-student-group-packages-update-list"
                :per_page="pagination.per_page"
                :actual_page.sync="pagination.actual_page"
                :records="pagination.records"
                :query_change="pagination.query_change"
                :load_data="load_data"
            />
        </div>

        <template #modal-footer>
            <b-button @click="hideConfirmModal()" variant="danger">Zavrieť</b-button>
        </template>
    </b-modal>
</template>

<script>
import PackageCard from "@/components/single/PackageCard";
import VuePagination from "@/components/VuePagination";
import debounce from 'lodash.debounce';

export default {
    name: "ModalPackageAdd",
    components: {
        PackageCard,
        VuePagination,
    },
    props: {
        packages_data: {
            type: Array,
            default() {
                return []
            }
        },
        pagination: {
            type: Object,
            default() {
                return {}
            }
        },
        package_search: {
            type: String,
            default: ''
        },
        load_data: {
            type: Boolean,
            default() {
                return false
            }
        },
        packages_selected: {
            type: Array,
            default() {
                return []
            }
        },
    },
    data() {
        return {
            package_search_term: this.package_search,
        }
    },
    watch:{
        'pagination.actual_page': function (value) {
            this.changeActualPage(value)
        },
        package_search_term() {
            this.debounceInput()
        },
    },
    methods: {
        hideConfirmModal() {
            this.resetActualPage()
            this.$bvModal.hide('modal-package-add')
        },
        changeActualPage(actual_page) {
            this.$emit('update:pagination.actual_page', actual_page)
        },
        resetActualPage() {
            this.$emit('reset-actual-page')
        },
        updatePackages(package_card) {
            this.$emit('update-packages', package_card)
        },
        debounceInput: debounce(function () {
            this.resetActualPage()
            this.$emit('update:package_search', this.package_search_term)
        }, 500),
        buttonTypes(button_id) {
            if (this.packages_selected.includes(button_id)) {
                return 'remove'
            } else {
                return 'add'
            }
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep #modal-package-add {
    .package-add-body {
        @extend %flex-column-center;

        .package-list {
            @extend %flex-column-center;
            width: 100%;
        }
    }

    .modal-footer {
        .btn {
            margin-top: 0;
        }

        .btn.btn-primary-lighter {
            min-width: em(150);
        }
    }
}
</style>