<template>
    <div class="page-profile-settings">
        <MainMenu :title="'Nastavenia'" />

        <div class="profile-settings-container container">
            <BeatLoader class="mt-5" v-if="spinners.load_data" />
            <ProfileSettingsForm
                v-else
                :customer_data_user.sync="customer_user"
                :customer_data_teacher.sync="customer_teacher"
                :customer_data_student.sync="customer_student"
                :post_data="spinners.post_data"
                :errors="response_type.failed"
                @post-update-customer="postUpdateCustomer($event)"
            />
        </div>

        <BodyImageBackground />
    </div>
</template>

<script>
import config from "@/config";

import MainMenu from '@/components/MainMenu.vue';
import ProfileSettingsForm from "@/components/profile/ProfileSettingsForm";
import BodyImageBackground from "@/components/BodyImageBackground";
import BeatLoader from 'vue-spinner/src/BeatLoader.vue';

export default {
    name: "PageProfileSettings",
    metaInfo() {
        return {
            title: 'Nastavenia | Profil | ',
        }
    },
    components: {
        MainMenu,
        ProfileSettingsForm,
        BodyImageBackground,
        BeatLoader,
    },
    data() {
        return {
            customer_user: {},
            customer_teacher: {},
            customer_student: {},

            spinners: {
                load_data: false,
                post_data: false,
            },
            response_type: {
                failed: null,
            }
        }
    },
    created() {
        this.getUserSettings()
    },
    methods: {
        getUserSettings() {
            this.spinners.load_data = true
            this.axios.get(config.API_URL + '/user/getUserCustomer'
                + '?slug=' + this.$store.getters.user.slug, config.HEADERS)
                .then((response) => {
                    let responseData = response.data
                    if (responseData.code === 200) {
                        this.restructureCustomerData(responseData.data)
                    } else if (responseData.code === 401) {
                        this.$store.dispatch('logoutUser', false)
                    }
                    this.spinners.load_data = false
                }, (error) => {
                    console.warn(error);
                    this.spinners.load_data = false
                });
        },
        restructureCustomerData(data) {
            let customerUserData = {}
            customerUserData.name = data.customer.name
            customerUserData.surname = data.customer.surname
            customerUserData.email = data.email
            customerUserData.user_type_id = data.user_type_id
            customerUserData.password = ''
            customerUserData.password_confirmation = ''
            this.customer_user = customerUserData

            if (data.user_type_id === 1) {
                let customerUserTeacher = {}
                customerUserTeacher.country = data.customer.country
                customerUserTeacher.school_type_id = data.customer.school_type_id
                customerUserTeacher.school_name = data.customer.school_name
                customerUserTeacher.phone = data.customer.phone
                this.customer_teacher = customerUserTeacher
            } else if (data.user_type_id === 2) {
                let customerUserStudent = {}
                customerUserStudent.parent_email = data.customer.parent_email
                this.customer_student = customerUserStudent
            }
        },
        postUpdateCustomer(customer_data) {
            if (this.spinners.post_data) return false
            this.spinners.post_data = true
            this.axios.post(config.API_URL + '/user/updateUserCustomer', customer_data, config.HEADERS)
                .then((response) => {
                    let responseData = response.data
                    if (responseData.code === 200) {
                        this.response_type.failed = ''
                        this.$router.push({ name: 'PageProfile' }, () => {})
                        this.$store.dispatch('updateCustomerData', responseData.data)
                    } else if (responseData.code === 401) {
                        this.$store.dispatch('logoutUser', false)
                    } else {
                        this.response_type.failed = responseData.errors
                    }
                    this.spinners.post_data = false
                }, (error) => {
                    console.warn(error);
                    this.spinners.post_data = false
                });
        }
    },
}
</script>

<style lang="scss" scoped>
.page-profile-settings::v-deep {
    @extend %page-position-bg-image;

    .profile-settings-container {
        @extend %container-position-bg-image;

        .response-type {
            max-width: em(400);
            margin: em(20) auto;
        }
    }
}
</style>