<template>
    <div class="component-study-group-packages-list">
        <b-row v-show="packages_selected.length > 0" class="package-cards-head">
            <b-col cols="8" sm="7" offset-sm="1" class="package-title">Názov</b-col>
            <b-col cols="2" class="package-count">Počet kartičiek</b-col>
        </b-row>

        <div class="package-cards-list">
            <PackageCard
                v-for="card in packages_list"
                :key="card.id"
                :package_card="card"
                :button_type="default_button"
                @update-packages="updatePackage($event)"
            />
        </div>

        <div
            v-if="lang_from !== null && lang_to !== null && $store.getters.check_permission('manage own groups')"
            @click="openModalPackageAdd()"
            class="add-package"
        >
            <img :src="button_add_package" class="add-package__button" alt="image" />
            <p class="add-package__text">Pridať balík/y</p>
        </div>
    </div>
</template>

<script>
import button_add_package from '@/assets/button_add_package.svg';
import PackageCard from '@/components/single/PackageCard';

export default {
    name: "StudyGroupAddList",
    components: {
        PackageCard,
    },
    props: {
        packages_list: {
            type: Array,
            default() {
                return []
            }
        },
        packages_selected: {
            type: Array,
            default() {
                return []
            }
        },
        lang_from: {
            type: Number,
            default: null
        },
        lang_to: {
            type: Number,
            default: null
        },
        default_button: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            button_add_package: button_add_package,
        }
    },
    methods: {
        openModalPackageAdd() {
            this.$emit('clear-success-update-message')
            this.$bvModal.show('modal-package-add')
        },
        updatePackage(remove_package) {
            this.$emit('remove-package', remove_package.id)
        },
    },
}
</script>

<style lang="scss" scoped>
.component-study-group-packages-list::v-deep {
    width: 100%;

    .package-cards-head {
        margin-top: em(20);
        font-size: em(12);

        .package-title {
            text-align: left;
        }

        .package-count {
            @extend %flex-column-center;
        }
    }

    .package-cards-list {
        @extend  %flex-column-center;
        margin-bottom: em(20);
    }

    .add-package {
        &__button {
            cursor: pointer;
        }
    }
}
</style>