<template>
    <div class="component-test-results-students">
        <b-row class="test-results-head">
            <b-col cols="6" class="head-title">Meno</b-col>
            <b-col cols="1" lg="2">Stav</b-col>
            <b-col cols="2">Výsledok</b-col>
            <b-col cols="3" lg="2">Kontrola</b-col>
        </b-row>

        <hr class="test-results-hr">

        <b-row
            v-for="student in students"
            :key="student.id"
            @click="goCheckTest(student.id, student.state)"
            class="test-results-students"
            :class="{ 'rest-results-hover': student.state === 1 }"
        >
            <b-col cols="6" class="member-title">{{ student.user.name }}</b-col>
            <b-col cols="1" lg="2">
                <div v-if="student.state === 1" class="form-input-checkbox">
                    <input
                        :id="'student_state_' +  student.id"
                        class="form-check-input"
                        v-model="checkbox_explanation"
                        :value="true"
                        type="checkbox"
                        disabled
                    />
                </div>
                <div v-else>-</div>
            </b-col>
            <b-col cols="2">
                <div v-if="student.state === 1">
                    {{ student.right_answers_count }} / {{ student.question_count }}
                </div>
                <div v-else>-</div>
            </b-col>
            <b-col cols="3" lg="2" class="member-check">
                <div v-if="student.state === 1">
                    <img v-show="student.teacher_control === 0" :src="test_no_checked" class="test-no-checked" alt="image" />
                    <img v-show="student.teacher_control === 1" :src="test_checked" class="test-checked" alt="image" />
                </div>
                <div v-else>-</div>
            </b-col>
        </b-row>

        <b-row class="test-results-explanations">
            <b-col cols="6" lg="3">
                <input v-model="checkbox_explanation" :value="true" type="checkbox" class="form-check-input" id="checkbox-explanation" disabled />
                <label class="form-check-label explanations-description" for="checkbox-explanation">Test odovzdaný</label>
            </b-col>
            <b-col cols="6" lg="3">
                -
                <span class="explanations-description">Nevypracované</span>
            </b-col>
            <b-col cols="6" lg="3">
                <img :src="test_checked" class="test-checked" alt="image" />
                <span class="explanations-description">Skontrolované</span>
            </b-col>
            <b-col cols="6" lg="3">
                <img :src="test_no_checked" class="test-no-checked" alt="image" />
                <span class="explanations-description">Neskontrolované</span>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import test_checked from "@/assets/test_teacher/test_checked.svg";
import test_no_checked from "@/assets/test_teacher/test_no_checked.svg";

export default {
    name: "TestResultsStudents",
    props: {
        test_id: {
            type: Number,
            default: 0
        },
        students: {
            type: Array,
            default() {
                return []
            }
        },
    },
    data() {
        return {
            test_checked: test_checked,
            test_no_checked: test_no_checked,
            checkbox_explanation: true,
        }
    },
    methods: {
        goCheckTest(test_id, test_state) {
            if (test_state === 1) this.$router.push({ name: 'PageTestControl', query: { test: test_id } }, () => {})
        },
    }
}
</script>

<style lang="scss" scoped>
.component-test-results-students::v-deep {
    width: 100%;
    max-width: em(700);

    .test-results-head {
        .head-title {
            text-align: left;
        }
    }

    .test-results-hr {
        margin-top: 0;
    }

    .test-results-students {
        //margin-bottom: em(10);
        padding: em(10) 0;
        border-radius: em(4);

        > div {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .member-title {
            justify-content: flex-start;
        }

        .member-check {
            .test-checked, .test-no-checked {
                cursor: pointer;
            }
        }

    }

    .rest-results-hover {
        cursor: pointer;
        transition: background-color 0.1s ease;

        &:hover {
            background-color: $green;
        }
    }

    .test-results-explanations {
        margin-top: em(100);

        > div {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .explanations-description {
            font-size: em(14);
            margin-left: em(10);
        }
    }

    @media (max-width: 991px) {
        .test-results-head {
            font-size: em(12);
        }

        .test-results-explanations {
            margin-bottom: em(100);

            .explanations-description {
                font-size: em(10);
            }
        }
    }
}
</style>