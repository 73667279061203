<template>
    <div class="page-test-results">
        <BeatLoader class="mt-5 mb-5" v-if="spinners.load_data_info || spinners.load_data_results" />
        <div v-else class="container test-results-container">
            <h1 class="test-title">{{ test_data_info.test_number }}</h1>

            <TestResultHead :test_info="test_data_info" />

            <div class="test-code">
                <p>Kód testu: {{ test_data_info.test_number }}</p>
<!--                <p>Link na test: www.eduzo.sk/254574</p>-->
            </div>

            <TestResultsStudents
                :test_id="test_data_info.id"
                :students="test_list_students"
            />
        </div>
    </div>
</template>

<script>
import config from "@/config";
import BeatLoader from 'vue-spinner/src/BeatLoader.vue';
import TestResultHead from "@/components/study_group/teacher_test/TestResultHead";
import TestResultsStudents from "@/components/study_group/teacher_test/TestResultsStudents";

export default {
    name: "PageTestResults",
    metaInfo() {
        return {
            title: 'Výsledky testov | ',
        }
    },
    components: {
        BeatLoader,
        TestResultHead,
        TestResultsStudents,
    },
    data() {
        return {
            test_data_info: {},
            test_list_students: [],
            spinners: {
                load_data_info: false,
                load_data_results: false,
            },
        }
    },
    mounted() {
        this.getStudyGroupTestInfo()
        this.getStudyGroupTestResults()
    },
    methods: {
        getStudyGroupTestInfo() {
            this.spinners.load_data_info = true
            this.axios.get(config.API_URL + '/test/getTestsLandingByNumber'
                + '?test_number=' + this.$route.params.test_number, config.HEADERS)
                .then((response) => {
                    let responseData = response.data
                    if (responseData.code === 200) {
                        this.test_data_info = responseData.data
                    } else if (responseData.code === 401) {
                        this.$store.dispatch('logoutUser', false)
                    }
                    this.spinners.load_data_info = false
                }, (error) => {
                    console.warn(error);
                    this.spinners.load_data_info = false
                });
        },
        getStudyGroupTestResults() {
            this.spinners.load_data_results = true
            this.axios.get(config.API_URL + '/test/getTestsByNumber'
                + '?test_number=' + this.$route.params.test_number, config.HEADERS)
                .then((response) => {
                    let responseData = response.data
                    if (responseData.code === 200) {
                        this.test_list_students = responseData.data.data
                    } else if (responseData.code === 401) {
                        this.$store.dispatch('logoutUser', false)
                    }
                    this.spinners.load_data_results = false
                }, (error) => {
                    console.warn(error);
                    this.spinners.load_data_results = false
                });
        },
    }
}
</script>

<style lang="scss" scoped>
.page-test-results::v-deep {
    .test-results-container {
        @extend %flex-column-center;

        .test-title {
            margin-bottom: em(10);
        }

        .test-code {
            display: flex;
            justify-content: space-between;

            width: 100%;
            max-width: em(700);
            margin: em(30) 0;
        }
    }
}
</style>