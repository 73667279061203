<template>
    <b-modal id="study-groups-modal-add-to-group">
        <template #modal-title>Pridanie do skupiny</template>

        <b-form>
            <b-form-group label="Zadajte kód skupiny*" label-for="input_group_code">
                <b-form-input id="input_group_code" v-model="group.code" type="text"></b-form-input>
                <div class="error-message-form" v-show="$v.group.code.$error">
                    <p v-show="!$v.group.code.required">Zadajte kód skupiny.</p>
                </div>
            </b-form-group>
        </b-form>

        <ResponseError v-if="response_type.failed" :errors="response_type.failed" />

        <template #modal-footer>
            <b-button @click="hideConfirmModal()" variant="secondary">
                Zrušiť
            </b-button>
            <b-button @click="updateProfile()" class="send-data-button" variant="primary-lighter">
                <BeatLoader v-if="spinners.post_data" />
                <span v-else>PRIDAŤ SA DO SKUPINY</span>
            </b-button>
        </template>
    </b-modal>
</template>

<script>
import config from "@/config";
import BeatLoader from 'vue-spinner/src/BeatLoader.vue';
import { required } from "vuelidate/lib/validators";
import ResponseError from "@/components/ResponseError";

export default {
    name: "StudyGroupsModalAddToGroup",
    components: {
        ResponseError,
        BeatLoader,
    },
    data() {
        return {
            group: {
                code: '',
            },
            spinners: {
                post_data: false,
            },
            response_type: {
                failed: null,
            },
        }
    },
    methods: {
        hideConfirmModal() {
            this.code = ''
            this.$v.$reset()
            this.$bvModal.hide('study-groups-modal-add-to-group')
        },
        updateProfile() {
            this.$v.$touch()
            if (this.$v.$invalid) return false
            this.postGroupCode()
        },
        postGroupCode() {
            this.spinners.post_data = true
            this.axios.post(config.API_URL + '/group/attachUserByCode', this.group, config.HEADERS)
                .then((response) => {
                    let responseData = response.data
                    if (responseData.code === 200) {
                        this.$emit('update-groups')
                        this.hideConfirmModal()
                    } else if (responseData.code === 401) {
                        this.$store.dispatch('logoutUser', false)
                    } else {
                        this.response_type.failed = responseData.errors
                    }
                    this.spinners.post_data = false
                }, () => {
                    this.spinners.post_data = false
                });
        }
    },
    validations: {
        group: {
            code: {
                required,
            }
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep #study-groups-modal-add-to-group {
    .btn {
        margin: 0;
    }

    .send-data-button {
        margin-left: em(10);
        min-width: em(275, 20);
    }


    @media (max-width: 991px) {
        min-width: em(165, 20);
    }
}
</style>