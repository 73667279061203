<template>
    <div class="component-profile-settings-form-user">
        <b-form-group label="Typ účtu*" label-for="select_account_type">
            <b-form-select id="select_account_type" v-model="customer_data.user_type_id" :options="user_types" class="input_select_type" disabled></b-form-select>
        </b-form-group>

        <b-form-group label="Meno*" label-for="input_name" :class="{ 'input--error': $v.customer_data.name.$error }">
            <b-form-input id="input_name" v-model="customer_data.name" type="text"></b-form-input>
            <div class="error-message-form" v-show="$v.customer_data.name.$error">
                <p v-show="!$v.customer_data.name.required">Prosím zadajte Vaše meno.</p>
            </div>
        </b-form-group>

        <b-form-group label="Priezvisko*" label-for="input_surname" :class="{ 'input--error': $v.customer_data.surname.$error }">
            <b-form-input id="input_surname" v-model="customer_data.surname" type="text"></b-form-input>
            <div class="error-message-form" v-show="$v.customer_data.surname.$error">
                <p v-show="!$v.customer_data.surname.required">Prosím zadajte Vaše priezvisko.</p>
            </div>
        </b-form-group>

        <b-form-group label="Email*" label-for="input_email" :class="{ 'input--error': $v.customer_data.email.$error }">
            <b-form-input id="input_email" v-model="customer_data.email" type="email"></b-form-input>
            <div class="error-message-form" v-show="$v.customer_data.email.$error">
                <p v-show="!$v.customer_data.email.required || !$v.customer_data.email.email">Prosím zadajte Váš platný email.</p>
            </div>
        </b-form-group>

        <b-form-group label="Nové heslo*" label-for="input_password" :class="{ 'input--error': $v.customer_data.password.$error }">
            <b-form-input id="input_password" v-model="customer_data.password" type="password"></b-form-input>
            <div class="error-message-form" v-show="$v.customer_data.password.$error">
                <p v-show="!$v.customer_data.password.minLength">Heslo musí obsahovať minimálne {{ $v.customer_data.password.$params.minLength.min }} znakov.</p>
            </div>
        </b-form-group>

        <b-form-group label="Potvrď nové heslo*" label-for="input_password_confirmation" :class="{ 'input--error': $v.customer_data.password_confirmation.$error }">
            <b-form-input id="input_password_confirmation" v-model="customer_data.password_confirmation" type="password"></b-form-input>
            <div class="error-message-form" v-show="$v.customer_data.password_confirmation.$error">
                <p v-show="!$v.customer_data.password_confirmation.sameAs">Heslá sa musia zhodovať.</p>
            </div>
        </b-form-group>
    </div>
</template>

<script>
import { email, minLength, required, sameAs } from "vuelidate/lib/validators";

export default {
    name: "ProfileSettingsFormUser",
    props: {
        customer_data: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    data() {
        return {
            user_types: [
                { value: 1, text: 'Učiteľ' },
                { value: 2, text: 'Študent' },
            ],
        }
    },
    validations: {
        customer_data: {
            name: {
                required,
            },
            surname: {
                required,
            },
            email: {
                required,
                email,
            },
            password: {
                minLength: minLength(6)
            },
            password_confirmation: {
                sameAsPassword: sameAs('password')
            },
        },
    },
}
</script>

<style lang="scss" scoped>
.component-profile-settings-form-user::v-deep {
    @extend %flex-column-center;
    width: 100%;
}
</style>