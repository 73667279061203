<template>
    <div class="page-learning">
        <MainMenu :title="'Učenie'" />

        <div class="learning-container container">
            <div v-if="type_questions === null" class="select-learning-type">
                <h1 class="mb-3">Ahoj, vyber si formu učenia:</h1>
                <b-button @click="selectLearningType(0)" variant="primary-lighter">
                    Učenie formou výberu <strong>správnej odpovede</strong>
                </b-button>
                <b-button @click="selectLearningType(1)" variant="primary-lighter">
                    Učenie formou výberu z možností <strong>Nevedel / Ťažké / Vedel / Ľahké</strong>
                </b-button>
            </div>
            <div v-else>
                <b-button @click="selectLearningType(null)" class="change-type" variant="primary-lighter" :disabled="possibility_change_type">
                    Zmeniť typ učenia
                </b-button>

                <LearningCorrectOption
                    v-if="type_questions === 0"
                    @possibility-change="possibilityChangeTypeLearning($event)"
                />

                <LearningDifficultyOption
                    v-if="type_questions === 1"
                    @possibility-change="possibilityChangeTypeLearning($event)"
                />
            </div>
        </div>

        <BodyImageBackground />
    </div>
</template>

<script>
import MainMenu from "@/components/MainMenu";
import LearningCorrectOption from "@/components/learning/LearningCorrectOption";
import LearningDifficultyOption from "@/components/learning/LearningDifficultyOption";
import BodyImageBackground from "@/components/BodyImageBackground";

export default {
    name: "PageLearning",
    metaInfo() {
        return {
            title: 'Učenie | ',
        }
    },
    components: {
        MainMenu,
        LearningCorrectOption,
        LearningDifficultyOption,
        BodyImageBackground,
    },
    data() {
        return {
            type_questions: null,
            possibility_change_type: true,
        }
    },
    methods: {
        selectLearningType(learning_type) {
            this.type_questions = learning_type
        },
        possibilityChangeTypeLearning(value) {
            this.possibility_change_type = value
        },
    }
}
</script>

<style lang="scss" scoped>
.page-learning::v-deep {
    @extend %page-position-bg-image;

    .learning-container {
        @extend %container-position-bg-image;

        .select-learning-type {
            @extend %flex-column-center;
            margin-top: em(100);

            .btn {
                width: 100%;
                max-width: em(700, 20);
            }
        }

        .change-type {
            font-size: em(14);
            margin-top: 0;
        }
    }

    @media (max-width: 991px) {
        .select-learning-type {
            margin-top: em(50);
        }
    }
}
</style>