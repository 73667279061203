<template>
    <div class="component-form-user">
        <b-form-group label="Typ účtu*" label-for="select_account_type">
            <b-form-select id="select_account_type" v-model="customer_data.user_type_id" :options="user_types" class="input_select_type"></b-form-select>
        </b-form-group>

        <b-form-group label="Meno*" label-for="input_name" :class="{ 'input--error': $v.customer_data.name.$error }">
            <b-form-input id="input_name" v-model="customer_data.name" type="text"></b-form-input>
            <div class="error-message-form" v-show="$v.customer_data.name.$error">
                <p v-show="!$v.customer_data.name.required">Prosím zadajte Vaše meno.</p>
            </div>
        </b-form-group>

        <b-form-group label="Priezvisko*" label-for="input_surname" :class="{ 'input--error': $v.customer_data.surname.$error }">
            <b-form-input id="input_surname" v-model="customer_data.surname" type="text"></b-form-input>
            <div class="error-message-form" v-show="$v.customer_data.surname.$error">
                <p v-show="!$v.customer_data.surname.required">Prosím zadajte Vaše priezvisko.</p>
            </div>
        </b-form-group>

        <b-form-group label="Email*" label-for="input_email" :class="{ 'input--error': $v.customer_data.email.$error }">
            <b-form-input id="input_email" v-model="customer_data.email" type="email"></b-form-input>
            <div class="error-message-form" v-show="$v.customer_data.email.$error">
                <p v-show="!$v.customer_data.email.required || !$v.customer_data.email.email">Prosím zadajte Váš platný email.</p>
            </div>
        </b-form-group>

        <b-form-group label="Heslo*" label-for="input_password" :class="{ 'input--error': $v.customer_data.password.$error }">
            <b-form-input id="input_password" v-model="customer_data.password" type="password"></b-form-input>
            <div class="error-message-form" v-show="$v.customer_data.password.$error">
                <p v-show="!$v.customer_data.password.required || !$v.customer_data.password.minLength">Heslo musí obsahovať minimálne {{ $v.customer_data.password.$params.minLength.min }} znakov.</p>
            </div>
        </b-form-group>

        <b-form-group label="Potvrď heslo*" label-for="input_password_confirmation" :class="{ 'input--error': $v.customer_data.password_confirmation.$error }">
            <b-form-input id="input_password_confirmation" v-model="customer_data.password_confirmation" type="password"></b-form-input>
            <div class="error-message-form" v-show="$v.customer_data.password_confirmation.$error">
                <p v-show="!$v.customer_data.password_confirmation.required || !$v.customer_data.password_confirmation.sameAs">Heslá sa musia zhodovať.</p>
            </div>
        </b-form-group>
    </div>
</template>

<script>
import config from "@/config";
import { email, minLength, required, sameAs } from "vuelidate/lib/validators";

export default {
    name: "FormUser",
    props: {
        customer_data: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    data() {
        return {
            user_types: [
                { value: 1, text: 'Učiteľ' },
                { value: 2, text: 'Študent' },
            ],
        }
    },
    created() {
        this.getUserTypes()
    },
    methods: {
        getUserTypes() {
            this.axios.get(config.API_URL + '/getUserTypes', config.HEADERS)
                .then((response) => {
                    let responseData = response.data
                    if (responseData.code === 200) {
                        this.user_types = this.restructureUserTypes(responseData.data)
                    }
                }, (error) => {
                    console.warn(error);
                });
        },
        restructureUserTypes(data) {
            let newData = []
            data.forEach(obj => {
                let newObj = {}
                newObj.value = obj.id
                newObj.text = obj.user_type
                newData.push(newObj)
            })
            return newData
        },
    },
    validations: {
        customer_data: {
            name: {
                required,
            },
            surname: {
                required,
            },
            email: {
                required,
                email,
            },
            password: {
                required,
                minLength: minLength(6)
            },
            password_confirmation: {
                required,
                sameAsPassword: sameAs('password')
            },
        },
    },
}
</script>

<style lang="scss" scoped>
.component-form-user::v-deep {
    @extend %flex-column-center;
    width: 100%;
}
</style>