<template>
    <div class="page-study-group">
        <MainMenu :title="group_name" />

        <div class="study-group-container container">
            <StudyGroupMenu />

            <div v-if="group_updated">
                <router-view></router-view>
            </div>
            <div v-else>
                <BeatLoader />
            </div>
        </div>

        <BodyImageBackground />
    </div>
</template>

<script>
import MainMenu from "@/components/MainMenu";
import StudyGroupMenu from "@/components/study_group/StudyGroupMenu";
import BeatLoader from 'vue-spinner/src/BeatLoader.vue';
import BodyImageBackground from "@/components/BodyImageBackground";

export default {
    name: "PageStudy",
    metaInfo() {
        return {
            title: this.meta_data.title ? this.meta_data.title +  ' | '  : 'Študijná skupina | ',
            meta: [
                { vmid: 'og:type', name:'og:type', content: 'skupina' },
            ]
        }
    },
    components: {
        MainMenu,
        StudyGroupMenu,
        BeatLoader,
        BodyImageBackground,
    },
    data() {
        return {
            group_updated: false,
            group_name: 'EDUZO',
            meta_data: {
                title: ''
            }
        }
    },
    mounted() {
        this.$store.dispatch("updateStudyGroup", this.$route.params.slug)
            .then(response => {
                if (response) {
                    this.group_updated = response
                    this.group_name = this.$store.getters.study_group.name
                    this.meta_data.title = this.$store.getters.study_group.name
                } else {
                    this.$router.replace({ name: 'PageHome' }, () => {})
                }
            }, error => {
                console.error("Got nothing from server. Prompt user to check internet connection and try again", error)
            })
    },
}
</script>

<style lang="scss" scoped>
.page-study-group::v-deep {
    @extend %page-position-bg-image;

    .study-group-container {
        @extend %container-position-bg-image;
    }
}
</style>