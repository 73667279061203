import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router/index';
import createPersistedState from "vuex-persistedstate";
import axios from "axios";
import config from "@/config";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: '',
        test_time: '',
        test_questions: [],
        test_question_index: 0,
        test_question_index_last: 0,
        study_group: '',
    },
    getters: {
        user: state => {
            return state.user
        },
        test_time: state => {
            return state.test_time
        },
        test_questions: state => {
            return state.test_questions
        },
        test_question_index: state => {
            return state.test_question_index
        },
        test_question_by_index: state => (index) => {
            return state.test_questions[index]
        },
        test_question_index_difference: state => {
            return (state.test_question_index_last - state.test_question_index)
        },
        study_group: state => {
            return state.study_group
        },
        check_permission: state => (permission) => {
            const allPermissions = state.user.permissions
            return allPermissions.includes(permission)
        },
    },
    mutations: {
        setUser(state, data) {
            state.user = data;
        },
        setTestTime(state, data) {
            state.test_time = data;
        },
        setTestQuestions(state, data) {
            state.test_questions = data
        },
        setTestQuestionIndex(state, data) {
            data ? state.test_question_index += 1 : state.test_question_index -= 1
        },
        setTestQuestionIndexLast(state, data) {
            state.test_question_index_last = data
        },
        resetTestData(state) {
            state.test_time = ''
            state.test_questions = []
            state.test_question_index = 0
            state.test_question_index_last = 0
        },
        setStudyGroup(state, data) {
            state.study_group = data;
        },
        clearVuexData(state) {
            state.user = ''
            state.test_time = ''
            state.test_questions = []
            state.test_question_index = 0
            state.test_question_index_last = 0
            state.study_group = ''
        },
    },
    actions: {
        loginUser({ commit }, data) {
            config.HEADERS.headers['Authorization'] = `Bearer ${ data.access_token }`;
            commit('setUser', data)
            router.push({ name: 'PageHome' }, () => {})
        },
        logoutUser({ state, dispatch }, post_logout) {
            if (post_logout) {
                axios.post(config.API_URL + '/auth/logout', state.user.access_token, config.HEADERS)
                    .then(() => {
                        dispatch('clearLogoutUserData')
                    }, (error) => {
                        console.warn(error)
                        dispatch('clearLogoutUserData')
                    });
            } else {
                dispatch('clearLogoutUserData')
            }
        },
        clearLogoutUserData({ commit }) {
            delete config.HEADERS.headers['Authorization'];
            commit('clearVuexData')
            router.push({ name: 'PageLogin' }, () => {})
        },
        updateStudyGroup({ commit }, slug) {
            return new Promise((resolve, reject) => {
                axios.get(config.API_URL + '/group/' + slug, config.HEADERS)
                    .then((response) => {
                        let responseData = response.data
                        if (responseData.code === 200) {
                            commit('setStudyGroup', responseData.data)
                            resolve(true)
                        } else if (responseData.code === 403 || responseData.code === 401) {
                            resolve(false)
                        }
                    }, (error) => {
                        console.warn(error)
                        reject(error);
                    });
            })
        },
        handleTestAnswer({ state, commit }, data) {
            let existAnswer = state.test_questions
            existAnswer.forEach(obj => {
                if (obj.id === data.id) obj.answer = data.answer
            })
            commit('setTestQuestions', existAnswer)
        },
        updateCustomerData({ state, commit }, data) {
            let customer = state.user
            customer.name = data.name
            customer.slug = data.slug
            customer.email = data.email
            commit('setUser', customer)
        }
    },
    modules: {
    },
    plugins: [
        createPersistedState()
    ],
})
