<template>
    <div class="page-study-group-share">
        <MainMenu :title="'Pozvánka do skupiny'" />

        <div class="study-group-share-container container">
            <h1>Pozvánka do skupiny</h1>

            <p class="mt-1">Pozvánka do skupiny bola odoslaná na Váš email.</p>

            <p class="mt-5">Kod triedy:</p>
            <h2 class="mt-1 mb-5">{{ $route.query.group_code }}</h2>

            <b-row class="group-share-buttons">
                <b-col cols="12" lg="6">
                    <b-button
                        variant="primary-lighter"
                        @click="$router.push({ name: 'PageHome' })"
                    >
                        Domov
                    </b-button>
                </b-col>
                <b-col cols="12" lg="6">
                    <b-button
                        variant="primary-lighter"
                        @click="$router.push({ name: 'PageStudyGroupPackages', params: { slug: $route.params.slug } })"
                    >
                        Prejsť do skupiny
                    </b-button>
                </b-col>
            </b-row>

        </div>

        <BodyImageBackground />
    </div>
</template>

<script>
import MainMenu from "@/components/MainMenu";
import BodyImageBackground from "@/components/BodyImageBackground";

export default {
    name: "PageStudyGroupShare",
    components: {
        MainMenu,
        BodyImageBackground,
    },
}
</script>

<style lang="scss" scoped>
.page-study-group-share::v-deep {
    @extend %page-position-bg-image;

    .study-group-share-container {
        @extend %container-position-bg-image;
        @extend %flex-column-center;
        max-width: em(800);

        .group-share-buttons {
            width: 100%;
            max-width: em(500);

            .btn {
                width: 100%;
            }
        }
    }
}
</style>