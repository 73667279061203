<template>
    <div class="page-page-study-group-packages">
        <p v-if="!spinners.load_data_actual && packages_list.length === 0" class="mt-5">
            Neboli pridané žiadne balíčky.
        </p>

        <StudyGroupPackagesList
            v-show="lang_from_id && lang_to_id"
            :packages_list.sync="packages_list"
            :lang_from="lang_from_id"
            :lang_to="lang_to_id"
            :default_button="'play'"
            @clear-success-update-message="clearResponseMessage()"
        />

        <VuePagination
            id="paginator-student-group-packages-list"
            :per_page="pagination_actual.per_page"
            :actual_page.sync="pagination_actual.actual_page"
            :records="pagination_actual.records"
            :query_name="pagination_actual.query_name"
            :query_change="pagination_actual.query_change"
            :load_data="spinners.load_data_actual"
        />

        <ModalPackageAdd
            :packages_data="packages_data"
            :pagination="pagination_all"
            :package_search.sync="package_search"
            :load_data="spinners.load_data_all"
            :packages_selected="packages_selected"
            @reset-actual-page="resetActualPage()"
            @update-packages="updatePackages($event)"
        />

        <div v-if="package_update" class="send-buttons">
            <b-button @click="updateStudyGroupPackages()" variant="primary-lighter">
                <BeatLoader v-if="spinners.post_data" />
                <span v-else>ULOŽIŤ ZMENY</span>
            </b-button>
            <p class="m-auto warning-description"><small><em>*urobili ste zmeny, ktoré sa prejavia až po uložení</em></small></p>
        </div>

        <ResponseSuccess v-if="response_type.success" :success="response_type.success" />
        <ResponseError v-if="response_type.failed" :errors="response_type.failed" />
    </div>
</template>

<script>
import config from "@/config";
import StudyGroupPackagesList from "@/components/study_group/StudyGroupPackagesList";
import ModalPackageAdd from "@/components/ModalPackageAdd";
import BeatLoader from 'vue-spinner/src/BeatLoader.vue';
import ResponseError from "@/components/ResponseError";
import ResponseSuccess from "@/components/ResponseSuccess";
import VuePagination from "@/components/VuePagination";

export default {
    name: "PageStudyGroupPackages",
    components: {
        VuePagination,
        StudyGroupPackagesList,
        ModalPackageAdd,
        BeatLoader,
        ResponseError,
        ResponseSuccess,
    },
    data() {
        return {
            lang_from_id: null,
            lang_to_id: null,

            pagination_actual: {
                per_page: 10,
                actual_page: 1,
                records: 0,
                query_name: 'strana',
                query_change: true,
            },
            pagination_all: {
                per_page: 10,
                actual_page: 1,
                records: 0,
                query_change: false,
            },

            packages_data: [],
            packages_list: [],
            packages_selected: [],
            packages_selected_update: true,
            packages_selected_default: [],
            package_search: '',
            package_update: false,

            spinners: {
                load_data_actual: false,
                load_data_all: false,
                post_data: false,
            },
            response_type: {
                success: '',
                failed: null,
            },
        }
    },
    watch: {
        // 'pagination.actual_page': function () {
        //     this.getStudyGroupPackages()
        // },
        '$route.query.strana': function () {
            this.setActualPage()
        },
        'pagination_all.actual_page': function () {
            this.getAllPackages()
        },
        package_search() {
            this.getAllPackages()
        },
        packages_selected() {
            this.package_update = this.compareSelectedId();
        },
    },
    mounted() {
        this.lang_from_id = this.$store.getters.study_group.lang_from_id
        this.lang_to_id = this.$store.getters.study_group.lang_to_id

        this.setActualPage()
        this.getAllPackages()
    },
    methods: {
        compareSelectedId() {
            let id_include = false
            if (this.packages_selected.length === this.packages_selected_default.length) {
                this.packages_selected.forEach(package_id => {
                    if (!this.packages_selected_default.includes(package_id)) id_include = true
                })
            } else {
                id_include = true
            }
            return id_include
        },
        setActualPage() {
            this.$route.query[this.pagination_actual.query_name]
                ? this.pagination_actual.actual_page = Number(this.$route.query[this.pagination_actual.query_name])
                : this.pagination_actual.actual_page = 1
            this.getStudyGroupPackages()
        },
        getStudyGroupPackages() {
            this.spinners.load_data_actual = true
            this.axios.get(config.API_URL + '/group/getGroupPackages'
                + '?perPage=' + this.pagination_actual.per_page
                + '&page=' + this.pagination_actual.actual_page
                + '&slug=' + this.$route.params.slug, config.HEADERS)
                .then((response) => {
                    let responseData = response.data
                    if (responseData.code === 200) {
                        this.packages_list = responseData.data.data
                        if (this.packages_selected_update) {
                            this.packages_selected_default = JSON.parse(JSON.stringify(responseData.data.packages_ids))
                            this.packages_selected = JSON.parse(JSON.stringify(responseData.data.packages_ids))
                            this.packages_selected_update = false
                        }
                        this.pagination_actual.records = responseData.data.total
                    } else if (responseData.code === 401) {
                        this.$store.dispatch('logoutUser', false)
                    }
                    this.spinners.load_data_actual = false
                }, (error) => {
                    console.warn(error);
                    this.spinners.load_data_actual = false
                });
        },
        clearResponseMessage() {
            this.response_type.success = ''
            this.response_type.failed = null
        },
        getAllPackages() {
            this.spinners.load_data_all = true
            this.axios.get(config.API_URL + '/packages/getAll'
                + '?body=' + this.package_search
                + '&perPage=' + this.pagination_all.per_page
                + '&page=' + this.pagination_all.actual_page
                + '&lang_from_id=' + this.lang_from_id
                + '&lang_to_id=' + this.lang_to_id, config.HEADERS)
                .then((response) => {
                    let responseData = response.data
                    if (responseData.code === 200) {
                        this.packages_data = responseData.data.data
                        this.pagination_all.records = responseData.data.total
                    } else if (responseData.code === 401) {
                        this.$store.dispatch('logoutUser', false)
                    }
                    this.spinners.load_data_all = false
                }, (error) => {
                    console.warn(error)
                    this.spinners.load_data_all = false
                });
        },
        resetActualPage() {
            this.pagination_all.actual_page = 1
        },
        updatePackages(new_package) {
            let existPackage = this.packages_selected.some(package_id => package_id === new_package.id);
            existPackage ? this.removePackage(new_package.id) : this.packages_selected.push(new_package.id)
        },
        removePackage(package_id) {
            this.packages_selected = this.packages_selected.filter(id => id !== package_id)
        },

        updateStudyGroupPackages() {
            const updateGroupData = new FormData();
            if (this.packages_selected.length > 0) {
                this.packages_selected.forEach((package_id, index) => {
                    updateGroupData.set('packages[' + [index] + ']', package_id);
                })
            } else {
                updateGroupData.set('packages[]', '');
            }
            // for (let pair of updateGroupData.entries()) {
            //     console.log(pair[0]+ ', ' + pair[1]);
            // }
            this.postStudyGroupData(updateGroupData)
        },
        postStudyGroupData(post_data) {
            if (this.spinners.post_data) return false
            this.spinners.post_data = true
            this.axios.post(config.API_URL + '/group/' + this.$route.params.slug, post_data, config.HEADERS)
                .then((response) => {
                    let responseData = response.data
                    if (responseData.code === 200) {
                        this.package_update = false
                        this.getStudyGroupPackages()
                        this.response_type.success = responseData.message
                    } else if (responseData.code === 401) {
                        this.$store.dispatch('logoutUser', false)
                    } else {
                        this.response_type.failed = responseData.errors.message
                    }
                    this.spinners.post_data = false
                }, (error) => {
                    console.warn(error)
                    this.spinners.post_data = false
                });
        },
    }
}
</script>

<style lang="scss" scoped>
.page-page-study-group-packages::v-deep {
    .package-list {
        @extend %flex-column-center;
    }

    .response-type {
        max-width: em(500);
        margin: em(20) auto;
    }

    .send-buttons {
        .btn {
            min-width: em(200, 20);
            margin-top: em(20);
        }

        .warning-description {
            color: $red;
        }
    }
}
</style>