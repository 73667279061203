<template>
    <div class="page-test-control container">
        <BeatLoader class="mt-5" v-if="spinners.load_data" />
        <div v-else class="text-control-container">
            <div class="test-user">
                TEST
                <span> - {{ $route.params.test_number }}</span>
                <span v-if="user_test_info.user"> - {{ user_test_info.user.name }}</span>
            </div>
            <div v-if="user_test_info.state === 1" class="test-user-time">
                <span>{{ moment(user_test_info.start_time).format('lll') }}</span> -
                <span>{{ moment(user_test_info.end_time).format('lll') }}</span>
            </div>

            <b-row v-if="user_test_info.state === 1" class="test-control-teacher">
                <b-col cols="2" offset="10" lg="1" class="control-count">
                    {{ user_test_info.right_answers_count }}/{{ user_test_info.question_count }}
                </b-col>
            </b-row>

            <hr>

            <b-row v-for="(answer, index) in user_test_answers" :key="answer.id" class="test-answers">
                <b-col cols="2" lg="1" offset-lg="1" class="test-answers__order">{{ index + 1 }}.</b-col>
                <b-col cols="8" class="test-answers__data">
                    <p>{{ answer.question }}</p>
                    <p v-if="answer.answer"><strong>{{ answer.answer }}</strong></p>
                    <p v-else>-</p>
                </b-col>
                <b-col cols="2" lg="1" class="test-answers__correct">
                    <input
                        :id="'correct_answer_' + answer.answer_id"
                        class="form-check-input"
                        v-model="answer.answer_state"
                        @change="changeCorrectAnswer(answer.answer_state, answer.answer_id)"
                        type="checkbox"
                        :value="1"
                        :unchecked-value="0"
                        :disabled="!$store.getters.check_permission('manage own groups')"
                    />
                </b-col>
            </b-row>

            <b-row class="test-control-explanations">
                <b-col cols="6">
                    <input v-model="checkbox_explanation" type="checkbox" class="form-check-input" id="checkbox-explanation-1" disabled />
                    <label class="form-check-label" for="checkbox-explanation-1">Správna odpoveď</label>
                </b-col>
                <b-col cols="6">
                    <input type="checkbox" class="form-check-input" id="checkbox-explanation-2" disabled />
                    <label class="form-check-label" for="checkbox-explanation-2">Nesprávna odpoveď</label>
                </b-col>
            </b-row>

            <b-row class="save-buttons" v-if="$store.getters.check_permission('manage own groups')">
                <b-col cols="6">
                    <b-button
                        @click="updateControlTest()"
                        class="save-buttons__button"
                        variant="primary-lighter"
                        :disabled="!user_test_info.state"
                    >
                        <BeatLoader v-if="spinners.post_data" />
                        <span v-else>ULOŽIŤ</span>
                    </b-button>
                </b-col>
                <b-col cols="6">
                    <b-button
                        @click="goBack()"
                        class="save-buttons__button"
                        variant="primary-lighter"
                    >
                        SPÄŤ
                    </b-button>
                </b-col>
            </b-row>
        </div>

        <ResponseError v-if="response_type.failed" :errors="response_type.failed" />
    </div>
</template>

<script>
import config from "@/config";
import BeatLoader from 'vue-spinner/src/BeatLoader.vue';
import ResponseError from "@/components/ResponseError";

export default {
    name: "PageTestControl",
    metaInfo() {
        return {
            title: 'Kontrola testu | ',
        }
    },
    components: {
        BeatLoader,
        ResponseError,
    },
    data() {
        return {
            checkbox_explanation: true,
            user_test_info: {},
            user_test_answers: {},
            spinners: {
                load_data: false,
                post_data: false,
            },
            response_type: {
                code: 0,
                failed: null,
            },
        }
    },
    mounted() {
        this.getStudyGroupTestAnswers()
    },
    methods: {
        goBack() {
            this.$router.push({ name: 'PageTestResults' })
        },
        getStudyGroupTestAnswers() {
            this.spinners.load_data = true
            this.axios.get(config.API_URL + '/test/getTestWithAll'
                + '?test_id=' + this.$route.query.test, config.HEADERS)
                .then((response) => {
                    let responseData = response.data
                    if (responseData.code === 200) {
                        this.user_test_info = responseData.data
                        this.user_test_answers = this.restructureTestQuestions(responseData.data.questions)
                    } else if (responseData.code === 422) {
                        this.response_type.failed = responseData.errors
                        this.response_type.code = 422
                    } else if (responseData.code === 401) {
                        this.$store.dispatch('logoutUser', false)
                    }
                    this.spinners.load_data = false
                }, (error) => {
                    console.warn(error);
                    this.spinners.load_data = false
                });
        },
        restructureTestQuestions(questions) {
            let newAnswers = []
            questions.forEach(answer => {
                let newAnswer = {}
                newAnswer.id = answer.id
                newAnswer.question = answer.question
                // newAnswer.question_answer = answer.question_answer
                newAnswer.answer_id = answer.answer.id
                newAnswer.answer = answer.answer.answer
                newAnswer.answer_state = answer.answer.state
                newAnswers.push(newAnswer)
            })
            return newAnswers
        },
        changeCorrectAnswer(value, answer_id) {
            let questions = this.user_test_answers
            questions.forEach(obj => {
                if (obj.answer_id === answer_id) obj.answer_state = value ? 1 : 0
            })
            this.user_test_answers = questions
            this.calculateCorrectAnswerCount(questions)
        },
        calculateCorrectAnswerCount(questions) {
            let correctCount = 0
            questions.forEach(obj => {
                if (obj.answer_state === 1) correctCount += 1
            })
            this.user_test_info.right_answers_count = correctCount
        },
        updateControlTest() {
            const postData = new FormData()
            postData.set('test_id', this.user_test_info.id);
            this.user_test_answers.forEach(answer => {
                postData.set('answers[' + [answer.answer_id] + ']', answer.answer_state);
            })
            // for (let pair of postData.entries()) {
            //     console.log(pair[0]+ ', ' + pair[1]);
            // }
            this.postControlTestData(postData)
        },
        postControlTestData(post_data) {
            this.spinners.post_data = true
            this.axios.post(config.API_URL + '/test/controlTestByTeacher?test_id=' + this.$route.query.test, post_data,config.HEADERS)
                .then((response) => {
                    let responseData = response.data
                    if (responseData.code === 200) {
                        this.$router.push({ name: 'PageTestResults' })
                    } else if (responseData.code === 401) {
                        this.$store.dispatch('logoutUser', false)
                    } else {
                        this.response_type.failed = responseData.errors.message
                    }
                    this.spinners.post_data = false
                }, (error) => {
                    console.warn(error);
                    this.spinners.post_data = false
                });
        },
    },
}
</script>

<style lang="scss" scoped>
.page-test-control::v-deep {
    .text-control-container {
        width: 100%;
        max-width: em(700);
        margin: 0 auto;
    }

    .test-user {
        font-size: em(20);
    }

    .test-user-time {
        margin-bottom: em(20);
    }

    .test-control-teacher {
        margin-bottom: em(10);

        .control-checkbox {
            text-align: left;

            .custom-control-input {
                cursor: pointer;
            }

            .custom-control-label {
                margin-left: em(10);
                cursor: pointer;
            }
        }

        .control-count {
            @extend %flex-column-center;
        }
    }

    .test-answers {
        > div {
            margin-bottom: em(10);
        }

        &__order, &__correct {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__data {
            display: flex;
            align-items: flex-start;
            flex-direction: column;

            p {
                text-align: left;
            }
        }

        &__correct {
            .custom-control-input {
                cursor: pointer;
            }
        }
    }

    .test-control-explanations {
        margin: em(20);

        > div {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }

        .custom-control-label {
            margin-left: em(10);
        }
    }

    .save-buttons {
        display: flex;
        justify-content: space-around;
        width: 100%;
        max-width: 400px;
        margin: 0 auto;

        &__button {
            width: 100%;
        }
    }

    @media (max-width: 991px) {
        .text-control-container {
            .test-user {
                font-size: em(12);
            }

            .save-buttons {
                margin-bottom: em(100);
            }
        }
    }
}
</style>