<template>
    <div class="page-sign-in">
        <LoginMenu />

        <div class="container">
            <b-row class="login-wrapper">
                <b-col cols="12" lg="7" class="login-wrapper__image">
                    <img :src="asset_1" width="671" height="618" class="asset_1" alt="image" />
                </b-col>
                <b-col cols="12" lg="5" class="login-wrapper__body">
                    <div class="login-form-wrapper">
                        <h1 class="login-form-wrapper__title">Prihlásenie</h1>
                        <img :src="asset_2" class="asset_2 m-display-none" alt="image" />

                        <b-form @submit.prevent="loginUser()" class="login-form-wrapper__form">
                            <b-form-group label="Email*" label-for="email_input" :class="{ 'input--error': $v.login_data.email.$error }">
                                <b-form-input id="email_input" v-model="login_data.email" type="email"></b-form-input>
                                <div class="error-message-form" v-show="$v.login_data.email.$error">
                                    <p v-show="!$v.login_data.email.required || !$v.login_data.email.email">Prosím zadajte platný email.</p>
                                </div>
                            </b-form-group>

                            <b-form-group label="Heslo*" label-for="password_input" :class="{ 'input--error': $v.login_data.password.$error }">
                                <b-form-input id="password_input" v-model="login_data.password" type="password"></b-form-input>
                                <div class="error-message-form" v-show="$v.login_data.password.$error">
                                    <p v-show="!$v.login_data.password.required">Prosím zadajte heslo.</p>
                                </div>
                            </b-form-group>

                            <ResponseError v-show="response_type.failed !== ''" :errors="response_type.failed" />

                            <b-button type="submit" variant="primary-lighter">
                                <BeatLoader v-if="spinners.post_data" />
                                <span v-else>PRIHLÁSIŤ SA</span>
                            </b-button>

                            <small @click="$router.push({ name: 'PageForgotPassword' }, () => {})" class="forgotten-password">
                                Zabudnuté heslo?
                            </small>
                        </b-form>
                    </div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import config from "@/config";
import login_main_image from "@/assets/login/login_main_image.svg";
import login_asset_1 from "@/assets/login/login_asset_1.svg";
import LoginMenu from "@/components/login/LoginMenu";
import BeatLoader from 'vue-spinner/src/BeatLoader.vue';

import { required, email } from 'vuelidate/lib/validators';
import ResponseError from "@/components/ResponseError";

export default {
    name: "PageLogin",
    metaInfo() {
        return {
            title: 'Prihlásenie | ',
        }
    },
    components: {
        ResponseError,
        LoginMenu,
        BeatLoader,
    },
    data() {
        return {
            asset_1: login_main_image,
            asset_2: login_asset_1,

            login_data: {
                email: '',
                password: '',
            },
            spinners: {
                post_data: false,
            },
            response_type: {
                failed: null,
            },
        }
    },
    methods: {
        loginUser() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.$nextTick(() => {
                    this.$scrollTo('.input--error')
                })
                return false
            }
            this.postLoginUser()
        },
        postLoginUser() {
            if (this.spinners.post_data) return false
            this.spinners.post_data = true
            this.error = ''

            this.axios.post(config.API_URL + '/auth/login', this.login_data, config.HEADERS)
                .then((response) => {
                    let responseData = response.data
                    if (responseData.code === 200) {
                        this.$store.dispatch('loginUser', responseData.data)
                    } else {
                        this.response_type.failed = responseData.errors
                    }
                    this.spinners.post_data = false
                }, () => {
                    this.spinners.post_data = false
                });
        },
    },
    validations: {
        login_data: {
            email: {
                required,
                email,
            },
            password: {
                required,
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.page-sign-in::v-deep {
    background-color: $body-gray;
    min-height: 100vh;
    padding-bottom: em(70);

    .login-wrapper {
        margin-top: em(50);

        &__image {
            position: relative;

            .asset_1 {
                width: 90%;
                height: auto;
            }
        }

        &__body {
            display: flex;
            justify-content: center;
            position: relative;

            .login-form-wrapper {
                @extend %flex-column-center;
                width: 100%;

                &__title {
                    position: relative;
                    margin: 10px 0;
                    z-index: 1;
                }

                .asset_2 {
                    margin: em(-25) auto 0;
                    width: 100%;
                    max-width: em(200);
                }

                &__form {
                    .btn {
                        min-width: em(160);
                    }

                    .forgotten-password {
                        margin: em(3) auto;
                        cursor: pointer;
                        color: $small;
                    }

                    .component-response-error {
                        margin: 0;

                        .response-type {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 991px) {
        .login-wrapper {
            padding-top: 0;
            margin-top: 0;

            &__image {
                .asset_1 {
                    width: 70%;
                    margin: em(20) 0;
                }
            }

            &__body {
                .form-group {
                    margin-bottom: em(20);
                }
            }
        }
    }
}
</style>