<template>
    <div class="page-test-start">
        <BeatLoader class="mt-5" v-if="spinners.load_data" />
        <div v-else>
            <h1 class="test-name">Test číslo: {{ test_info.test_number }}</h1>
            <img :src="student_test_start" class="student-test-start" alt="image" />

            <b-row class="test-info">
                <b-col cols="12" sm="4" offset-sm="4">
                    <p>Počet slov v teste: </p>
                    <p>{{ test_info.question_count }}</p>
                </b-col>
                <b-col cols="12" sm="4" offset-sm="4">
                    <p>Čas na test:</p>
                    <p>{{ test_info.answering_time_number }} min</p>
                </b-col>
                <b-col cols="12" sm="4" offset-sm="4">
                    <p>Dátum vytvorenia:</p>
                    <p>{{ moment(test_info.created_at).format('L') }}</p>
                </b-col>
            </b-row>

            <p class="warning-message response-type response-type__failed">
                POZOR! Môžete sa vrátiť iba o jednu otázku naspäť.
            </p>

            <b-button @click="$router.push({ name: 'PageTestRun', params: { test_id: $route.params.test_id } })" variant="primary">
                ZAČAŤ TEST
            </b-button>
        </div>
    </div>
</template>

<script>
import config from "@/config";
import student_test_start from "@/assets/test_student/student_test_start.svg";
import BeatLoader from 'vue-spinner/src/BeatLoader.vue';

export default {
    name: "PageTestStart",
    metaInfo() {
        return {
            title: 'Test | ',
        }
    },
    components: {
        BeatLoader,
    },
    data() {
        return {
            student_test_start: student_test_start,
            test_info: {},
            spinners: {
                load_data: false,
            },
        }
    },
    mounted() {
        this.$store.commit('resetTestData')
        this.getStudyGroupTestInfo()
    },
    methods: {
        getStudyGroupTestInfo() {
            this.spinners.load_data = true
            this.axios.get(config.API_URL + '/test/getTestsLandingByNumber'
                + '?test_number=' + this.$route.params.test_number, config.HEADERS)
                .then((response) => {
                    let responseData = response.data
                    if (responseData.code === 200) {
                        this.test_info = responseData.data
                    } else if (responseData.code === 401) {
                        this.$store.dispatch('logoutUser', false)
                    }
                    this.spinners.load_data = false
                }, (error) => {
                    console.warn(error);
                    this.spinners.load_data = false
                });
        },
    },
}
</script>

<style lang="scss" scoped>
.page-test-start::v-deep {
    .student-test-start {
        position: relative;
        margin-top: em(-25);
    }

    .test-info {
        > div {
            display: flex;
            justify-content: space-between;
            margin-bottom: em(10);
        }
    }

    .warning-message {
        max-width: em(500);
        margin: em(50) auto;
    }

    @media (max-width: 991px) {
        .test-info {
            margin-top: 0;
        }

        .btn {
            margin-top: em(50);
        }
    }
}
</style>