<template>
    <div class="component-learning-difficulty-option">
        <div class="test-question">
            <BeatLoader v-if="spinners.load_data" />
            <h1 v-if="!spinners.load_data && question.word_from">{{ question.word_from.word }}</h1>
            <h2 v-if="question.word_to"><span v-show="show_answer">{{ question.word_to.word }}</span></h2>
        </div>

        <div v-if="!spinners.load_data" class="question-options">
            <b-button
                v-show="!show_answer"
                @click="showAnswer()"
                class="question-options__button show-answer"
                variant="primary-white"
            >
                Zobraziť odpoveď
            </b-button>

            <b-row v-show="show_answer" class="question-options-buttons-group">
                <b-col cols="3">
                    <div class="delay-time">1 minúta</div>
                    <b-button
                        @click="sendQuestionAnswer('dont_know')"
                        class="question-options__button word-not-know"
                        variant="primary-white"
                    >
                        Nevedel
                    </b-button>
                </b-col>

                <b-col cols="3">
                    <div class="delay-time">1 týžden</div>
                    <b-button
                        @click="sendQuestionAnswer('hard')"
                        class="question-options__button word-difficult"
                        variant="primary-white"
                    >
                        Ťažké
                    </b-button>
                </b-col>

                <b-col cols="3">
                    <div class="delay-time">2 týždne</div>
                    <b-button
                        @click="sendQuestionAnswer('know')"
                        class="question-options__button word-good"
                        variant="primary-white"
                    >
                        Vedel
                    </b-button>
                </b-col>

                <b-col cols="3">
                    <div class="delay-time">1 mesiac</div>
                    <b-button
                        @click="sendQuestionAnswer('easy')"
                        class="question-options__button word-easy"
                        variant="primary-white"
                    >
                        Ľahké
                    </b-button>
                </b-col>
            </b-row>

            <ResponseError v-if="response_type.failed" :errors="response_type.failed" />
        </div>
    </div>
</template>

<script>
import config from "@/config";
import BeatLoader from 'vue-spinner/src/BeatLoader.vue';
import ResponseError from "@/components/ResponseError";

export default {
    name: "LearningDifficultyOption",
    components: {
        ResponseError,
        BeatLoader,
    },
    data() {
        return {
            show_answer: false,
            question: {},

            spinners: {
                load_data: false,
            },
            response_type: {
                failed: null,
            },
        }
    },
    mounted() {
        this.getStudyQuestionFirst()
    },
    methods: {
        getStudyQuestionFirst() {
            this.spinners.load_data = true
            this.axios.get(config.API_URL + '/easy-hard/getWord'
                + '?slug=' + this.$route.params.slug, config.HEADERS)
                .then((response) => {
                    let responseData = response.data
                    if (responseData.code === 200) {
                        this.question = responseData.data
                    } else if (responseData.code === 401) {
                        this.$store.dispatch('logoutUser', false)
                    }
                    this.spinners.load_data = false
                }, (error) => {
                    console.warn(error);
                    this.spinners.load_data = false
                });
        },
        showAnswer() {
            this.show_answer = true
            this.$emit('possibility-change', true)
        },
        sendQuestionAnswer(answer) {
            this.show_answer = false
            this.spinners.load_data = true
            this.$emit('possibility-change', false)

            const wordAnswer = new FormData()
            wordAnswer.set('word_id', this.question.word_from.id);
            wordAnswer.set('react', answer);
            wordAnswer.set('slug', this.$route.params.slug);

            this.postQuestionAnswer(wordAnswer)
        },
        postQuestionAnswer(answer_data) {
            this.axios.post(config.API_URL + '/easy-hard/reactOnWord', answer_data, config.HEADERS)
                .then((response) => {
                    let responseData = response.data
                    if (responseData.code === 200) {
                        this.question = responseData.data
                    } else if (responseData.code === 401) {
                        this.$store.dispatch('logoutUser', false)
                    } else {
                        this.response_type.failed = responseData.errors
                    }
                    this.spinners.load_data = false
                }, (error) => {
                    console.warn(error);
                    this.spinners.load_data = false
                });
        }
    }
}
</script>

<style lang="scss" scoped>
.component-learning-difficulty-option::v-deep {

    .test-question {
        @extend %flex-column-center;
        justify-content: space-evenly;

        height: 100%;
        min-height: em(300);
    }

    .question-options {
        margin: em(50) 0;
        padding: em(15);

        .show-answer {
            max-width: em(600);
        }

        .question-options-buttons-group {
            > div {
                padding: 0 em(30);
            }
        }

        .delay-time {
            font-size: em(12);
            margin-bottom: em(3);
        }

        &__button {
            width: 100%;
            margin-bottom: em(10);
        }

        .word-not-know {
            background-color: $red;
        }

        .word-difficult {
            background-color: $primary-darken;
        }

        .word-good {
            background-color: $green;
        }

        .word-easy {
            background-color: $blue-light;
        }
    }

    @media (max-width: 991px) {
        .test-question {
            min-height: em(150);
        }

        .question-options {
            .question-options-buttons-group {
                > div {
                    padding: 0 em(5);
                }
            }
        }
    }
}
</style>