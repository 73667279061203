<template>
    <div class="component-form-teacher">
        <b-form-group label="Krajina*" label-for="teacher_country" :class="{ 'input--error': $v.customer_data.country.$error }">
            <b-form-input id="teacher_country" v-model="customer_data.country" type="text"></b-form-input>
            <div class="error-message-form" v-show="$v.customer_data.country.$error">
                <p v-show="!$v.customer_data.country.required">Prosím zadajte krajinu.</p>
            </div>
        </b-form-group>

        <b-form-group label="Druh školy*" label-for="teacher_school_type_id" :class="{ 'input--error': $v.customer_data.school_type_id.$error }">
            <b-form-select id="teacher_school_type_id" v-model="customer_data.school_type_id" :options="school_types" class="input_select_type"></b-form-select>
            <div class="error-message-form" v-show="$v.customer_data.school_type_id.$error">
                <p v-show="!$v.customer_data.school_type_id.required">Prosím zadajte typ Vašej školy.</p>
            </div>
        </b-form-group>

        <b-form-group label="Názov školy*" label-for="teacher_school_name" :class="{ 'input--error': $v.customer_data.school_name.$error }">
            <b-form-input id="teacher_school_name" v-model="customer_data.school_name" type="text"></b-form-input>
            <div class="error-message-form" v-show="$v.customer_data.school_name.$error">
                <p v-show="!$v.customer_data.school_name.required">Prosím zadajte meno Vašej školy.</p>
            </div>
        </b-form-group>

        <b-form-group label="Tel. číslo*" label-for="teacher_phone" :class="{ 'input--error': $v.customer_data.phone.$error }">
            <b-form-input id="teacher_phone" v-model="customer_data.phone" type="tel"></b-form-input>
            <div class="error-message-form" v-show="$v.customer_data.phone.$error">
                <p v-show="!$v.customer_data.phone.required || !$v.customer_data.phone.minLength || !$v.customer_data.phone.maxLength || !$v.customer_data.phone.numeric">Prosím zadajte tel. číslo vo formáte 09xxxxxxxx.</p>
            </div>
        </b-form-group>
    </div>
</template>

<script>
import config from "@/config";
import { required, minLength, maxLength, numeric } from "vuelidate/lib/validators";

export default {
    name: "FormTeacher",
    props: {
        customer_data: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    data() {
        return {
            school_types: [
                { value: null, text: 'Zvoľte si druh školy...', notEnabled: true },
                { value: 1, text: 'Materská škola' },
                { value: 2, text: 'Základná škola' },
                { value: 3, text: 'Stredná škola' },
                { value: 4, text: 'Vysoká škola' },
                { value: 5, text: 'Jazyková škola' },
                { value: 6, text: 'Súkromný učitel' },
                { value: 7, text: 'Iné' },
            ],
        }
    },
    created() {
        this.getSchoolTypes()
    },
    methods: {
        getSchoolTypes() {
            this.axios.get(config.API_URL + '/getSchoolTypes', config.HEADERS)
                .then((response) => {
                    let responseData = response.data
                    if (responseData.code === 200) {
                        this.school_types = this.restructureUserTypes(responseData.data)
                    }
                }, (error) => {
                    console.warn(error);
                });
        },
        restructureUserTypes(data) {
            let newData = []
            data.forEach(obj => {
                let newObj = {}
                newObj.value = obj.id
                newObj.text = obj.school_type
                newData.push(newObj)
            })
            return newData
        },
    },
    validations: {
        customer_data: {
            country: {
                required,
            },
            school_type_id: {
                required,
            },
            school_name: {
                required,
            },
            phone: {
                required,
                minLength: minLength(10),
                maxLength: maxLength(10),
                numeric,
            },
        },
    },
}
</script>

<style lang="scss" scoped>
.component-form-teacher::v-deep {
    @extend %flex-column-center;
    width: 100%;

    .vue-tel-input {
        width: 100%;
        max-width: em(400);
        border-radius: em(4);
        min-height: em(38);
        border-color: rgb(206, 212, 218);

        .vti__dropdown {
            border-top-left-radius: em(4);
            border-bottom-left-radius: em(4);
            background-color: $white;
            border-right: 1px solid rgb(206, 212, 218);

            .vti__selection {
            }
        }

        .vti__input {
            border-top-right-radius: em(4);
            border-bottom-right-radius: em(4);
            background: $white;
        }
    }
}
</style>