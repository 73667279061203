<template>
    <div class="component-packages-tree">
        <b-row class="packages-tree-head m-display-none">
            <b-col cols="9" class="packages-tree-head__title">Názov</b-col>
            <b-col cols="2">Počet kartičiek</b-col>
            <b-col cols="1"></b-col>
        </b-row>
        <treeselect
            v-model="value"
            :multiple="true"
            :options="packages_data"
            :alwaysOpen="true"
            :maxHeight="-1"
            :default-expand-level="0"
            :allow-transition="true"
            :show-count="true"
        >
            <label slot="option-label" slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }" :class="labelClassName">
<!--                {{ node.isBranch ? 'true - with nested children' : 'false - without children' }}-->
                <b-row @click="startStudy(node.raw.slug)" class="option-package-card">
                    <b-col cols="9" class="option-package-card__label">{{ node.label }}</b-col>
                    <b-col cols="2" class="option-package-card__words">
                        <span class="package-words-count">{{ node.raw.words }}</span>
                    </b-col>
                    <b-col cols="1" class="option-package-card__count">
                        <span v-if="shouldShowCount" :class="countClassName">({{ count }})</span>
                    </b-col>
                </b-row>
            </label>
        </treeselect>
    </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
    name: "PackagesTree",
    components: {
        Treeselect,
    },
    props: {
        packages_data: {
            type: Array,
            default() {
                return []
            }
        },
    },
    data() {
        return {
            value: null,
        }
    },
    methods: {
        startStudy(package_slug) {
            this.$router.push({ name: 'PageLearning', params: { slug: package_slug }}, () => {})
        },
    }
}
</script>

<style lang="scss" scoped>
$colors: $red, $orange, $yellow, $green, $blue, $purple;

.component-packages-tree::v-deep {
    .packages-tree-head {
        width: 100%;
        margin-bottom: em(-15);
        font-size: em(12);

        &__title {
            padding-left: em(30);
            text-align: left;
        }
    }
    .vue-treeselect {
        position: relative;

        &__control {
            display: none;
        }

        &__menu-container {
            position: relative;

            .vue-treeselect__menu {
                position: relative;
                border: 0;
                box-shadow: none;
                background: transparent;
            }
        }

        .vue-treeselect__menu .vue-treeselect__list .vue-treeselect__list-item.vue-treeselect__indent-level-0 {

            // default option set
            .vue-treeselect__option {
                border-radius: em(4);
                margin-top: em(3);
                padding-left: 0;
                padding-right: 0;

                .vue-treeselect__option-arrow-placeholder, .vue-treeselect__option-arrow-container {
                    width: em(30);

                    .vue-treeselect__option-arrow {
                        color: white;
                    }
                }

                .vue-treeselect__label-container {

                    .vue-treeselect__checkbox-container {
                        display: none;
                    }

                    .vue-treeselect__label {
                        width: 100%;

                        .option-package-card {
                            width: 100%;

                            &__label {
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }

                            &__words, &__count {
                                text-align: center;
                            }
                        }
                    }
                }
            }

            @for $i from 1 through length($colors) {
                &:nth-child(#{length($colors)}n+#{$i}) {
                    > .vue-treeselect__option {
                        padding: em(3) 0;
                        margin-top: em(10);
                        background: nth($colors, $i);
                    }

                    > .vue-treeselect__list .vue-treeselect__list-item {
                        > .vue-treeselect__option {
                            margin-left: em(30);
                            width: calc(100% - 30px);
                            background: lighten(nth($colors, $i), 10%);
                        }

                        > .vue-treeselect__list .vue-treeselect__list-item {
                            > .vue-treeselect__option {
                                margin-left: em(60);
                                width: calc(100% - 60px);
                                background: lighten(nth($colors, $i), 15%);
                            }
                        }
                    }
                }
            }
        }

        // :hover
        .vue-treeselect__option--highlight {
            background: transparent;
        }
    }

    @media (max-width: 991px) {
        .vue-treeselect {
            font-size: em(12);
        }
    }
}
</style>