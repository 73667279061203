<template>
    <div class="single-study-groups-list-card" @click="redirectStudyGroupStudents(study_group.slug)">
        <b-row class="group-card">
            <b-col cols="10" lg="6" class="group-card__title"><strong>{{ study_group.name }}</strong></b-col>
            <b-col cols="2" class="m-display-none">{{ study_group.code }}</b-col>
            <b-col cols="1" class="m-display-none">
                {{ study_group.languages.language_to_short }}
            </b-col>
            <b-col cols="1" class="m-display-none">
                {{ study_group.languages.language_from_short }}
            </b-col>
            <b-col cols="1" class="m-display-none">{{ study_group.current_user_count }}</b-col>
            <b-col cols="2" lg="1" class="group-card__svg">
                <img :src="study_start" :width="35" :height="34" class="group-card-image" alt="image"/>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import study_start from '@/assets/study_group_start.svg';

export default {
    name: "StudyGroupsListCard",
    props: {
        study_group: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    data() {
        return {
            study_start: study_start,
        }
    },
    methods: {
        redirectStudyGroupStudents(group_slug) {
            this.$router.push({ name: 'PageStudyGroupStudents', params: { slug: group_slug } }, () => {})
        },
    }
}
</script>

<style lang="scss" scoped>
.single-study-groups-list-card::v-deep {
    border-radius: em(4);
    cursor: pointer;
    margin-bottom: em(10);
    min-height: em(40);
    //the background-color is set in the parent component -> StudyGroupsList

    .group-card {
        > div:not(:nth-child(1)) {
            @extend %flex-column-center;
            padding-top: em(3);
            padding-bottom: em(3);
        }

        > div:nth-child(1) {
            @extend %flex-column-center;
            align-items: flex-start;
            padding-top: em(3);
            padding-bottom: em(3);
        }

        > div:last-child {
            @extend %flex-column-center;
            align-items: flex-end;
        }

        &__title {
           padding-left: em(40);
        }

        &__svg {
            .group-card-image {
                width: auto;
                height: auto;
            }
        }
    }
}
</style>