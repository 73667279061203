<template>
    <b-form class="component-profile-settings-form">
        <ProfileSettingsFormUser
            :customer_data.sync="customer_data_user"
            ref="form_update_user"
        />

        <FormTeacher
            v-show="customer_data_user.user_type_id === 1"
            :customer_data.sync="customer_data_teacher"
            ref="form_update_teacher"
        />

        <FormStudent
            v-show="customer_data_user.user_type_id === 2"
            :customer_data.sync="customer_data_student"
            ref="form_update_student"
        />

        <ResponseError v-if="errors" :errors="errors" />

        <b-button
            @click="updateCustomerData()"
            variant="primary-lighter"
        >
            <BeatLoader v-if="post_data" />
            <span v-else>ULOŽIŤ</span>
        </b-button>
    </b-form>
</template>

<script>
import ProfileSettingsFormUser from "@/components/profile/ProfileSettingsFormUser";
import FormStudent from "@/components/login/FormStudent";
import FormTeacher from "@/components/login/FormTeacher";
import ResponseError from "@/components/ResponseError";
import BeatLoader from 'vue-spinner/src/BeatLoader.vue';

export default {
    name: "ProfileSettingsForm",
    components: {
        ProfileSettingsFormUser,
        FormStudent,
        FormTeacher,
        ResponseError,
        BeatLoader,
    },
    props: {
        customer_data_user: {
            type: Object,
            default() {
                return {}
            }
        },
        customer_data_student: {
            type: Object,
            default() {
                return {}
            }
        },
        customer_data_teacher: {
            type: Object,
            default() {
                return {}
            }
        },
        post_data: {
            type: Boolean,
            default() {
                return false
            }
        },
        errors: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    methods: {
        checkValidationData() {
            this.$refs.form_update_user.$v.$touch()
            if (this.customer_data_user.user_type_id === 1) this.$refs.form_update_teacher.$v.$touch()
            if (this.customer_data_user.user_type_id === 2) this.$refs.form_update_student.$v.$touch()

            if (this.$refs.form_update_user.$v.$invalid) {
                this.$nextTick(() => {
                    this.$refs.form_update_user.$scrollTo('.input--error')
                })
                return false
            }
            if (this.customer_data_user.user_type_id === 1) {
                if (this.$refs.form_update_teacher.$v.$invalid) {
                    this.$nextTick(() => {
                        this.$refs.form_update_teacher.$scrollTo('.input--error')
                    })
                    return false
                }
            } else if (this.customer_data_user.user_type_id === 2) {
                if (this.$refs.form_update_student.$v.$invalid) {
                    this.$nextTick(() => {
                        this.$refs.form_update_student.$scrollTo('.input--error')
                    })
                    return false
                }
            }
            return true
        },
        mergeCustomerData() {
            let mergedData
            if (this.customer_data_user.user_type_id === 1) {
                mergedData = {...this.customer_data_user, ...this.customer_data_teacher};
            } else if (this.customer_data_user.user_type_id === 2) {
                mergedData = {...this.customer_data_user, ...this.customer_data_student};
            }
            return mergedData
        },
        updateCustomerData() {
            if (this.post_data) return false
            if (!this.checkValidationData()) return false
            this.$emit('post-update-customer', this.mergeCustomerData())
        },
    }
}
</script>

<style lang="scss" scoped>
.component-profile-settings-form::v-deep {
    width: 100%;

    .btn {
        min-width: em(130);
    }

    @media (max-width: 991px) {
        margin-bottom: em(100);
    }
}
</style>