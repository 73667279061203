<template>
    <div class="page-test-create">
        <h1 class="test-title">Vytvorenie nového testu</h1>

        <TestCreateForm
            :new_test.sync="new_test"
            ref="form_new_test"
        />

        <b-row class="send-buttons">
            <b-col cols="12" v-for="(error, index) in response_type.failed" :key="index">
                <div class="response-type response-type__failed">{{ error }}</div>
            </b-col>
            <b-col cols="6">
                <b-button
                    @click="createNewTest()"
                    class="send-buttons__button"
                    variant="primary-lighter"
                >
                    <BeatLoader v-if="spinners.post_data" />
                    <span v-else>VYTVORIŤ</span>
                </b-button>
            </b-col>
            <b-col cols="6">
                <b-button
                    @click="goBack()"
                    class="send-buttons__button"
                    variant="primary-lighter"
                >
                    SPÄŤ
                </b-button>
            </b-col>
            <b-col cols="12" v-if="response_type.success">
                <ResponseSuccess :success="response_type.success" />
            </b-col>
        </b-row>
    </div>
</template>

<script>
import config from "@/config";
import BeatLoader from 'vue-spinner/src/BeatLoader.vue';
import TestCreateForm from "@/components/study_group/teacher_test/TestCreateForm";
import ResponseSuccess from "@/components/ResponseSuccess";

export default {
    name: "PageTestCreate",
    metaInfo() {
        return {
            title: 'Vytvorenie testu | ',
        }
    },
    components: {
        ResponseSuccess,
        BeatLoader,
        TestCreateForm,
    },
    data() {
        return {
            new_test: {
                // test_name: '',
                question_count: 10,
                answering_time_number: 5,
                slug: ''
             },
            spinners: {
                post_data: false
            },
            response_type: {
                success: '',
                failed: null,
            },
        }
    },
    methods: {
        goBack() {
            this.$router.push({ name: 'PageStudyGroupTests' }, () => {})
        },
        createNewTest() {
            this.$refs.form_new_test.$v.$touch()
            if (this.$refs.form_new_test.$v.$invalid) {
                this.$nextTick(() => {
                    this.$refs.form_new_test.$scrollTo('.input--error')
                })
                return false
            }
            this.new_test.slug = this.$route.params.slug
            this.postNewTestData()
        },
        postNewTestData() {
            this.spinners.post_data = true
            this.axios.post(config.API_URL + '/test/createTestForGroup', this.new_test, config.HEADERS)
                .then((response) => {
                    let responseData = response.data
                    if (responseData.code === 200) {
                        this.response_type.success = responseData.message
                    } else if (responseData.code === 401) {
                        this.$store.dispatch('logoutUser', false)
                    } else {
                        this.response_type.failed = responseData.errors
                    }
                    this.spinners.post_data = false
                }, () => {
                    this.spinners.post_data = false
                });
        }
    }
}
</script>

<style lang="scss" scoped>
.page-test-create::v-deep {
    @extend %flex-column-center;

    .test-title {
        margin-top: em(50);
        margin-bottom: em(50);
    }

    .send-buttons {
        display: flex;
        justify-content: space-around;
        width: 100%;
        max-width: em(400);

        &__button {
            width: 100%;
        }
    }

    @media (max-width: 991px) {
        .test-title {
            margin-top: 0;
            margin-bottom: em(20);
        }
    }
}
</style>