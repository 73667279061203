<template>
    <div class="component-study-group-tests-list-teacher">
        <b-row class="tests-group-head">
            <b-col cols="6" lg="6" offset-lg="1" class="test-title">Názov testu</b-col>
            <b-col cols="3" lg="2" class="test-check">Vytvorené</b-col>
            <b-col cols="3" lg="2" class="test-presence">Učasť</b-col>
            <b-col cols="12" lg="10" offset-lg="1">
                <hr class="test-hr">
            </b-col>
        </b-row>

        <b-row v-for="test in test_list" :key="test.id" @click="goTestResults(test.test_number)" class="test-group-body">
            <b-col cols="6" lg="6" offset-lg="1" class="test-name">
                {{ test.test_number }}
            </b-col>
            <b-col cols="3" lg="2" class="flexbox-center-body">
<!--                <img :src="test_checked" class="test-checked" alt="image" />-->
                {{ moment(test.created_at).format("L") }}
            </b-col>
            <b-col cols="3" lg="2" class="flexbox-center-body">
                {{ test.user_finished }} / {{ test.tests_count }}
            </b-col>
        </b-row>
    </div>
</template>

<script>
import test_checked from "@/assets/test_teacher/test_checked.svg";

export default {
    name: "StudyGroupTestsListTeacher",
    props: {
        test_list: {
            type: Array,
            default() {
                return []
            }
        },
    },
    data() {
        return {
            test_checked: test_checked,
        }
    },
    methods: {
        goTestResults(test_number) {
            this.$router.push({ name: 'PageTestResults', params: { test_number: test_number } }, () => {})
        }
    }
}
</script>

<style lang="scss" scoped>
.component-study-group-tests-list-teacher::v-deep {
    .tests-group-head {
        .test-title {
            text-align: left;
        }

        .test-check, .test-presence {
            @extend %flex-column-center;
        }

        .test-hr {
            margin: 0;
        }
    }

    .test-group-body {
        padding: em(10) 0;
        cursor: pointer;
        border-radius: em(4);
        transition: background-color 0.1s ease;

        .test-name {
            text-align: left;
        }

        &:hover {
            background-color: $primary;
        }
    }

    .flexbox-center-body {
        @extend %flex-column-center;
        justify-content: center;
    }
}
</style>