<template>
    <div class="page-student-test-finish">
        <img :src="student_test_finish" class="student-test-finish img-drag-none" alt="image">

        <h1>Test bol ukončený a odoslaný na kontrolu</h1>

        <div class="control-buttons">
            <b-button @click="$router.replace({ name: 'PageHome' })" variant="primary">DOMOV</b-button>
            <b-button @click="$router.push({ name: 'PageProfileTestResults' }, () => {})" variant="primary">VÝSLEDKY</b-button>
        </div>
    </div>
</template>

<script>
import student_test_finish from "@/assets/test_student/student_test_finish.svg";

export default {
    name: "PageStudentTestFinish",
    metaInfo() {
        return {
            title: 'Ukončenie testu | ',
        }
    },
    data() {
        return {
            student_test_finish: student_test_finish,
        }
    },
}
</script>

<style lang="scss" scoped>
.page-student-test-finish::v-deep {
    h1 {
        margin-top: em(30);
    }

    .control-buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: em(50);

        .btn {
            margin: 0 em(10);
        }
    }

    @media (max-width: 991px) {
        img {
            max-width: em(100);
        }

        .control-buttons {
            margin-top: em(20);
        }
    }
}
</style>