<template>
    <div class="page-profile">
        <MainMenu :title="'Profil'" />

        <div class="container">
            <b-row class="profile-menu">
                <b-col cols="12" sm="6" offset-sm="3" lg="4" offset-lg="4" class="user-type">
                    <img :src="profile_photo_default" class="user-type__photo" alt="image" />
                    <div class="user-type__name">
                        <h1>{{ $store.getters.user.name }}</h1>
                        <div>{{ $store.getters.user.user_type }}</div>
                    </div>
                </b-col>
                <b-col cols="12" sm="6" offset-sm="3" lg="4" offset-lg="4">
                    <b-button @click="logoutUser()" variant="primary">ODHLÁSIŤ SA</b-button>
                </b-col>
                <b-col cols="12" sm="6" offset-sm="3" lg="4" offset-lg="4" class="profile-buttons">
                    <b-button
                        v-if="$store.getters.user.user_type_id === 2"
                        @click="$router.push({ name: 'PageProfileTestResults' }, () => {})"
                        class="profile-buttons__button"
                        variant="primary-purple"
                    >
                        Výsledky testov
                    </b-button>

<!--                    <b-button-->
<!--                        v-if="$store.getters.user.user_type_id === 2"-->
<!--                        class="profile-buttons__button"-->
<!--                        variant="primary-yellow"-->
<!--                        disabled-->
<!--                    >-->
<!--                        Štatistiky učenia-->
<!--                    </b-button>-->

                    <b-button
                        @click="$router.push({ name: 'PageProfileSettings' }, () => {})"
                        class="profile-buttons__button"
                        variant="primary-green"
                    >
                        Nastavenia
                    </b-button>
                </b-col>
                <b-col cols="12" sm="6" offset-sm="3" lg="4" offset-lg="4" class="about-us">
                    <div>About Us</div>
                    <div>Verzia 20.12</div>
                    <div>Podpora</div>
                </b-col>
<!--                <b-col cols="12" sm="6" offset-sm="3" lg="4" offset-lg="4" class="delete-account">-->
<!--                    <b-button class="delete-account__button" variant="danger">VYMAZAŤ ÚČET</b-button>-->
<!--                </b-col>-->
            </b-row>
        </div>

        <BodyImageBackground />
    </div>
</template>

<script>
import profile_photo_default from "@/assets/profile_photo_default.svg";

import MainMenu from '@/components/MainMenu.vue';
import BodyImageBackground from "@/components/BodyImageBackground";

export default {
    name: "PageProfile",
    metaInfo() {
        return {
            title: 'Profil | ',
        }
    },
    components: {
        MainMenu,
        BodyImageBackground,
    },
    data() {
        return {
            profile_photo_default: profile_photo_default
        }
    },
    methods: {
        logoutUser() {
            this.$store.dispatch('logoutUser', true)
        },
    },
}
</script>

<style lang="scss" scoped>
.page-profile::v-deep {
    @extend %page-position-bg-image;

    .profile-menu {
        @extend %container-position-bg-image;
        padding: 0 em(30);

        > div {
            margin-bottom: em(20);
        }

        .user-type {
            &__photo {
                margin: em(20) 0;
            }
        }

        .profile-buttons {
            display: flex;
            flex-direction: column;
            padding: em(30) 0;

            &__button {
                margin: em(5) 0;
            }
        }

        .about-us {
            > div {
                margin-bottom: em(10);
            }
        }
    }

    @media (max-width: 991px) {
        .profile-menu {
            margin-bottom: em(100);
        }
    }
}
</style>