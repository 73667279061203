<template>
    <div class="component-study-groups">
        <h2>ŠTUDIJNÉ SKUPINY</h2>
        <p class="not-run-tests-description">
            <small>(skupiny, ktorých ste členom)</small>
        </p>

        <StudyGroupsList
            :study_groups="study_groups"
            :records="pagination.records"
            :load_data="spinners.load_data"
            :results_explanation="false"
        />
        <VuePagination
            id="paginator-student-group-list"
            :per_page="pagination.per_page"
            :actual_page.sync="pagination.actual_page"
            :records="pagination.records"
            :load_data="spinners.load_data"
            :query_name="pagination.query_name"
        />

        <StudyGroupsModalAddToGroup @update-groups="getStudyGroups()" />
    </div>
</template>

<script>
import config from "@/config";
import StudyGroupsList from "@/components/home/StudyGroupsList";
import StudyGroupsModalAddToGroup from "@/components/home/StudyGroupsModalAddToGroup";
import VuePagination from "@/components/VuePagination";

export default {
    name: "StudyGroups",
    components: {
        VuePagination,
        StudyGroupsList,
        StudyGroupsModalAddToGroup,
    },
    data() {
        return {
            pagination: {
                per_page: 5,
                actual_page: 1,
                records: 0,
                query_name: 'skupiny',
            },
            study_groups: [],
            spinners: {
                load_data: false,
            },
        }
    },
    watch: {
        '$route.query.skupiny': function () {
            this.setActualPage()
        },
    },
    created() {
        this.setActualPage()
    },
    methods: {
        setActualPage() {
            this.$route.query[this.pagination.query_name]
                ? this.pagination.actual_page = Number(this.$route.query[this.pagination.query_name])
                : this.pagination.actual_page = 1
            this.getStudyGroups()
        },
        getStudyGroups() {
            this.spinners.load_data = true
            this.axios.get(config.API_URL + '/group/getGroupList'
                + '?perPage=' + this.pagination.per_page
                + '&page=' + this.pagination.actual_page
                + '&orderBy=' + 'name'
                + '&ordering=' + 'ASC', config.HEADERS)
                .then((response) => {
                    let responseData = response.data
                    if (responseData.code === 200) {
                        this.study_groups = responseData.data.data
                        this.pagination.records = responseData.data.total
                    } else if (responseData.code === 401) {
                        this.$store.dispatch('logoutUser', false)
                    }
                    this.spinners.load_data = false
                }, () => {
                    this.spinners.load_data = false
                });
        }
    }
}
</script>

<style lang="scss" scoped>
.component-study-groups::v-deep {
    .not-run-tests-description {
        margin-bottom: em(15);
    }
}
</style>