var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-vue-paginator"},[(_vm.load_data)?_c('BeatLoader',{staticClass:"mt-1"}):_c('pagination',{attrs:{"per-page":_vm.per_page,"records":_vm.records,"options":{
            chunk: 5,
            theme: 'bootstrap4',
            edgeNavigation: false,
            texts: {
                count: 'Zobrazené {from} - {to} z {count} položiek|Počet položiek: {count}|',
                //count: 'Stránka {page} z {pages}',
                first: 'Prvá',
                last: 'Posledná'
            }
        }},on:{"paginate":_vm.handlePageChange},model:{value:(_vm.actual_page_child),callback:function ($$v) {_vm.actual_page_child=$$v},expression:"actual_page_child"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }