<template>
    <div class="wame-copyright">
        Copyright © {{ currentYear }} EDUZO | created by
        <a href="https://wame.sk/vyvoj-webovych-aplikacii" target="_blank">
            <img src="https://wame.sk/files/WAME_Logo_full.png" class="wame-logo" alt="wame-logo" />
        </a>
    </div>
</template>

<script>
export default {
    name: "WameCopyright",
    data() {
        return {
            currentYear: new Date().getFullYear(),
        }
    },
}
</script>

<style lang="scss" scoped>
.wame-copyright::v-deep {
    font-size: em(14);
    color: $black;
    width: 100%;
    position: absolute;
    bottom: 0;
    opacity: 0.8;
    @include backdrop-filter();
    background-color: inherit;

    .wame-logo {
        width: 80px;
        margin-top: -2px;
    }

    @media (max-width: 767px) {
        font-size: em(12);
        margin-bottom: 42px;
        z-index: 1;
    }
}
</style>