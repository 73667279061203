<template>
    <b-row class="single-package-card">
        <b-col cols="8" sm="7" offset-sm="1" class="package-card-name">{{ package_card.name }}</b-col>
        <b-col cols="2" class="package-card-count">{{ package_card.all_words_count }}</b-col>
        <b-col
            v-if="$store.getters.check_permission('manage own groups')"
            cols="2"
            @click="handleRedirect(package_card)"
            class="package-card-delete"
        >
            <img v-if="button_type === 'play'" :src="study_start" class="study-start" alt="image" />
            <img v-if="button_type === 'add'" :src="add_button_small" class="remove-button" alt="image" />
            <img v-if="button_type === 'remove'" :src="remove_button_small" class="remove-button" alt="image" />
        </b-col>
        <b-col
            v-else
            cols="2"
            class="package-card-study"
        >
            <img @click="handleRedirect(package_card)" :src="study_start" class="study-start" alt="image"/>
        </b-col>
    </b-row>
</template>

<script>
import remove_button_small from "@/assets/remove_button_small.svg";
import add_button_small from "@/assets/add_button_small.svg";
import study_start from '@/assets/study_group_start.svg';

export default {
    name: "PackageCard",
    props: {
        package_card: {
            type: Object,
            default() {
                return {}
            }
        },
        button_type: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            study_start: study_start,
            add_button_small: add_button_small,
            remove_button_small: remove_button_small,
        }
    },
    methods: {
        handleRedirect(package_card) {
            if (this.button_type === 'play') {
                this.$router.push({ name: 'PageLearning', params: { slug: package_card.slug }}, () => {})
            } else {
                this.$emit('update-packages', package_card)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.single-package-card::v-deep {
    width: 100%;
    margin: em(5) 0;
    padding: em(5) 0;

    border-radius: em(4);
    background-color: $green;

    .package-card-name, .package-card-count, .package-card-delete {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .package-card-name {
        justify-content: flex-start;
    }

    .package-card-delete {
        .remove-button {
            cursor: pointer;
        }
    }

    .study-start {
        max-width: em(30);
        cursor: pointer;
    }
    @media (max-width: 991px) {
        font-size: em(12)
    }
}
</style>