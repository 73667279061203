<template>
    <b-form class="component-form-new-test">
<!--        <b-form-group label="Názov testu*" label-for="test_name" :class="{ 'input&#45;&#45;error': $v.new_test.test_name.$error }">-->
<!--            <b-form-input id="test_name" v-model="new_test.test_name" type="text"></b-form-input>-->
<!--            <div class="error-message-form" v-show="$v.new_test.test_name.$error">-->
<!--                <p v-show="!$v.new_test.test_name.required">Prosím zadajte názov testu.</p>-->
<!--            </div>-->
<!--        </b-form-group>-->

        <b-form-group label="Počet slov*" label-for="question_count" :class="{ 'input--error': $v.new_test.question_count.$error }">
            <b-form-input id="question_count" v-model="new_test.question_count" type="number"></b-form-input>
            <div class="error-message-form" v-show="$v.new_test.question_count.$error">
                <p v-show="!$v.new_test.question_count.required">Prosím zadajte počet slov.</p>
            </div>
        </b-form-group>

        <b-form-group label="Celkové trvanie testu (počet minút)*" label-for="answering_time_number" :class="{ 'input--error': $v.new_test.answering_time_number.$error }">
            <b-form-input id="answering_time_number" v-model="new_test.answering_time_number" type="number"></b-form-input>
            <div class="error-message-form" v-show="$v.new_test.answering_time_number.$error">
                <p v-show="!$v.new_test.answering_time_number.required">Prosím zadajte celkové trvanie testu.</p>
            </div>
        </b-form-group>

    </b-form>
</template>

<script>
import button_add_package from "@/assets/button_add_package.svg";
import { required } from "vuelidate/lib/validators";

export default {
    name: "TestCreateForm",
    props: {
        new_test: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    data() {
        return {
            button_add_package: button_add_package,
        }
    },
    validations: {
        new_test: {
            question_count: {
                required,
            },
            answering_time_number: {
                required,
            },
        },
    },
}
</script>

<style lang="scss" scoped>
.component-form-new-test::v-deep {
    //.words-type {
    //    &__button {
    //        border: 0;
    //        width: 100%;
    //        color: $black;
    //        background-color: $white;
    //        box-shadow: 0 em(2) em(5) rgba(#000, 0.15);
    //    }
    //
    //    .active {
    //        box-shadow: inset 0 em(2) em(5) rgba(#000, 0.15);
    //    }
    //}
}
</style>