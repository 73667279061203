<template>
    <div class="component-response-success">
        <p class="response-type response-type__success">{{ success }}</p>
    </div>
</template>

<script>
export default {
    name: "ResponseSuccess",
    props: {
        success: {
            type: String,
            default: ''
        },
    },
}
</script>

<style lang="scss" scoped>
.component-response-success::v-deep {
    width: 100%;
    max-width: em(400);
    margin: em(20) auto;
}
</style>