<template>
    <div class="component-vue-paginator">
        <BeatLoader class="mt-1" v-if="load_data" />
        <pagination
            v-else
            :per-page="per_page"
            v-model="actual_page_child"
            :records="records"
            :options="{
                chunk: 5,
                theme: 'bootstrap4',
                edgeNavigation: false,
                texts: {
                    count: 'Zobrazené {from} - {to} z {count} položiek|Počet položiek: {count}|',
                    //count: 'Stránka {page} z {pages}',
                    first: 'Prvá',
                    last: 'Posledná'
                }
            }"
            @paginate="handlePageChange"
        />
    </div>
</template>

<script>
import BeatLoader from 'vue-spinner/src/BeatLoader.vue';

export default {
    name: "VuePagination",
    components: {
        BeatLoader,
    },
    props: {
        per_page: {
            type: Number,
            default: 20
        },
        actual_page: {
            type: Number,
            default: 1
        },
        records: {
            type: Number,
            default: 0
        },
        load_data: {
            type: Boolean,
            default: false
        },
        query_name: {
            type: String,
            default: 'strana'
        },
        query_change: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            actual_page_child: this.actual_page,
        }
    },
    watch: {
        actual_page() {
            this.actual_page_child = this.actual_page
        }
    },
    methods: {
        handlePageChange(page) {
            if (this.query_change) {
                let query = Object.assign({}, this.$route.query);
                if (page === 1) {
                    delete query[this.query_name]
                } else {
                    if (Number(this.$route.query[this.query_name]) !== page) query[this.query_name] = page
                }
                this.$router.push({ 'query': query }, () => {});
            }
            this.$emit('update:actual_page', page)
        },
    },
}
</script>

<style lang="scss" scoped>
.component-vue-paginator::v-deep {
    @extend %flex-column-center;
    margin-top: em(30);
    min-height: em(70);

    .VuePagination {
        &__pagination {
            margin-bottom: em(10);
        }

        &__count {
            font-size: em(14);
        }
    }

    @media (max-width: 991px) {
        .VuePagination {
            font-size: em(12);

            a.page-link {
                padding: em(3, 12) em(10, 12);
            }
        }
    }
}
</style>