<template>
    <div class="page-library">
        <MainMenu :title="'Knižnica'" />

        <div class="library-select-container container">
            <BeatLoader class="mt-5" v-if="spinners.load_data_languages" />
            <b-row v-else>
                <b-col cols="12" lg="6" class="select-language">
                    <b-form-group label-for="select_language_from_language" aria-placeholder="asdfasdf">
                        <b-form-select
                            id="select_language_from_language"
                            class="input_select_type"
                            v-model="lang_from_id"
                            :options="languages"
                        ></b-form-select>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="6" class="select-language">
                    <b-form-group label-for="select_language_to_study" placeholder="asdfasdf">
                        <b-form-select
                            id="select_language_to_study"
                            class="input_select_type"
                            v-model="lang_to_id"
                            :options="languages"
                        ></b-form-select>
                    </b-form-group>
                </b-col>
                <b-col cols="12" offset="0" sm="6" offset-sm="3">
                    <b-form-group label-for="input_search_term">
                        <b-form-input
                            id="input_search_term"
                            class="search-input"
                            v-model="search_term"
                            type="search"
                            placeholder="Zadajte hľadaný výraz..."
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <hr>
                </b-col>
            </b-row>

            <BeatLoader class="mt-5" v-show="spinners.load_data" />
            <p v-show="!spinners.load_data && packages_data.length === 0" class="mt-5">Pre túto kombináciu jazykov neboli vytvorené žiadne balíky.</p>
            <PackagesTree
                v-if="!spinners.load_data && packages_data.length > 0"
                :packages_data="packages_data"
            />
            <VuePagination
                id="paginator-library-packages"
                :per_page="pagination.per_page"
                :actual_page.sync="pagination.actual_page"
                :records="pagination.records"
                :load_data="spinners.load_data_packages"
                :query_change="pagination.query_change"
            />
        </div>

        <BodyImageBackground />
    </div>
</template>

<script>
import config from "@/config";
import MainMenu from "@/components/MainMenu";
import search_magnifier from "@/assets/search_magnifier.svg";
import BodyImageBackground from "@/components/BodyImageBackground";
import PackagesTree from "@/components/library/PackagesTree";
import VuePagination from "@/components/VuePagination";
import BeatLoader from 'vue-spinner/src/BeatLoader.vue';
import debounce from 'lodash.debounce';

export default {
    name: "PageLibrary",
    metaInfo() {
        return {
            title: 'Vyhľadávanie | ',
        }
    },
    components: {
        PackagesTree,
        MainMenu,
        BodyImageBackground,
        VuePagination,
        BeatLoader,
    },
    data() {
        return {
            search_magnifier: search_magnifier,

            pagination: {
                per_page: 20,
                actual_page: 1,
                records: 0,
                // query_name: 'strana',
                query_change: false,
            },

            default_item: { value: null, text: 'Prosím zvoľte si jazyk...', disabled: true },
            languages: [],
            lang_from_id: null,
            lang_to_id: null,
            search_term: '',
            packages_data: [],

            spinners: {
                load_data_languages: false,
                load_data_packages: false,
            },
        }
    },
    watch: {
        lang_from_id(lang_from_id) {
            this.handleRouteChange('jazyk_studia',lang_from_id)
        },
        lang_to_id(lang_to_id) {
            this.handleRouteChange('cielovy_jazyk',lang_to_id)
        },
        search_term: debounce(function () {
            this.getAllPackages()
        }, 300),
        'pagination.actual_page': function () {
            this.getAllPackages()
        }
    },
    mounted() {
        if (this.$route.query.jazyk_studia && this.$route.query.cielovy_jazyk) {
            this.lang_from_id = Number(this.$route.query.jazyk_studia)
            this.lang_to_id = Number(this.$route.query.cielovy_jazyk)
        } else {
            this.$router.replace({ 'query': null }, () => {});
        }
        this.getLanguageList()
    },
    methods: {
        handleRouteChange(query_name, lang_id) {
            this.pagination.actual_page = 1
            let query = Object.assign({}, this.$route.query);
            query[query_name] = lang_id
            this.$router.push({ 'query': query }, () => {});
            this.getAllPackages()
        },
        getLanguageList() {
            this.spinners.load_data_languages = true
            this.axios.get(config.API_URL + '/packages/getLanguageList', config.HEADERS)
                .then((response) => {
                    let responseData = response.data
                    if (responseData.code === 200) {
                        let languages = []
                        languages.push(this.default_item)
                        responseData.data.forEach(el => {
                            let language = {}
                            language.value = el.id
                            language.text = el.full_name
                            languages.push(language)
                        })
                        this.languages = languages
                    } else if (responseData.code === 401) {
                        this.$store.dispatch('logoutUser', false)
                    }
                    this.spinners.load_data_languages = false
                }, (error) => {
                    this.spinners.load_data_languages = false
                    console.warn(error)
                });
        },
        getAllPackages() {
            if (!this.lang_from_id || !this.lang_to_id) return false
            this.spinners.load_data_packages = true
            this.axios.get(config.API_URL + '/packages/getAll'
                + '?perPage=' + this.pagination.per_page
                + '&page=' + this.pagination.actual_page
                + '&body=' + this.search_term
                + '&children=' + '1'
                + '&lang_from_id=' + this.lang_from_id
                + '&lang_to_id=' + this.lang_to_id, config.HEADERS)
                .then((response) => {
                    let responseData = response.data
                    if (responseData.code === 200) {
                        this.packages_data = this.restructurePackages(responseData.data.data)
                        this.pagination.records = responseData.data.total
                    } else if (responseData.code === 401) {
                        this.$store.dispatch('logoutUser', false)
                    }
                    this.spinners.load_data_packages = false
                }, (error) => {
                    this.spinners.load_data_packages = false
                    console.warn(error)
                });
        },
        restructurePackages(data) {
            let dataList = []
            let dataLength = Object.keys(data).length
            for (let i = 0; i < dataLength; i++) {
                let newData = {}
                newData.id = data[i].id
                newData.label = data[i].name
                newData.slug = data[i].slug
                newData.words = data[i].all_words_count
                if (data[i].grand_children && data[i].grand_children.length > 0) newData.children = this.restructurePackages(data[i].grand_children)
                dataList.push(newData)
            }
            return dataList
        },
    }
}
</script>

<style lang="scss" scoped>
.page-library::v-deep {
    @extend %page-position-bg-image;

    .library-select-container {
        @extend %container-position-bg-image;
    }

    .select-language {
        margin: em(20) 0;
    }

    .search-input {
        margin: 0 auto;
        width: 100%;
        max-width: 325px;
        //background: search_magnifier no-repeat scroll 7px 7px;
    }

    @media (max-width: 991px) {
        .select-language {
            margin: em(10) 0;
            font-size: em(15);
        }

        .search-input {
            font-size: em(15);
        }
    }
}
</style>