<template>
    <div class="component-login-menu">
        <div class="main-wrapper container">
            <span class="login-menu-title" @click="goPageLogin()">EDUZO</span>

            <div class="menu-login">
                <div class="menu-login__buttons">
                    <b-button
                        @click="$router.push({ name: 'PageLogin' }, () => {})"
                        :class="{ no_active: $route.name !== 'PageLogin' }"
                        variant="primary"
                    >
                        Prihlásenie
                    </b-button>
                    <b-button
                        @click="$router.push({ name: 'PageRegistration' }, () => {})"
                        :class="{ no_active: $route.name !== 'PageRegistration' }"
                        variant="primary"
                    >
                        Registrácia
                    </b-button>
                </div>
                <img :src="slovakia_flag" class="slovakia-flag" alt="image" />
            </div>
        </div>
    </div>
</template>

<script>
import slovakia_flag from '@/assets/flags/slovakia_flag.svg';

export default {
    name: "LoginMenu",
    data() {
        return {
            slovakia_flag: slovakia_flag,
        }
    },
    methods: {
        goPageLogin() {
            this.$router.push({ name: 'PageLogin' }, () => {})
        },
    },
}
</script>

<style lang="scss" scoped>
.component-login-menu::v-deep {
    position: relative;
    padding-top: 20px;
    z-index: 1;

    .main-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .login-menu-title {
            font-size: 35px;
            cursor: pointer;
        }

        .menu-login {
            display: flex;

            &__buttons {
                margin-right: 20px;

                .no_active {
                    background-color: transparent;
                    outline: none;
                    border: 0;
                }
            }

            .slovakia-flag {
                object-fit: contain;
                height: auto;
            }
        }
    }

    @media (max-width: 991px) {
        .main-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;

            .login-menu-title {
                margin-bottom: 20px;
            }

            .menu-login {
                &__buttons {
                    margin-right: 0;
                }
            }

            .slovakia-flag {
                position: absolute;
                top: 10px;
                right: 10px;
                max-width: 35px;
                height: auto;
            }
        }
    }
}
</style>